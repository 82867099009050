import React, { useState, useEffect } from "react";
import { Sidebar } from "./AccountsSideBar";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { hasChildren } from "../PropertyConfiguration/PropertyDetails/utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Transactions from "./Transactions";
import Documents from "./Documents";
import { handleSpringDataRequest } from "../../api";
import { toast } from "material-react-toastify";

export default function RevenueDashboard(props) {
  const [groupId, setGroupId] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  useEffect(() => {
    setGroupId(props.groupId);
    props && setAccountInfo(props?.res);
  }, []);

  const [ledger, setLedger] = useState(null);
  const [accBalance, setAccBalance] = useState(null);

  const getLedgerData = () => {
    handleSpringDataRequest(
      `core/api/v1/account-ledger/get-all-invoices?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setLedger(res);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        toast.error(err);
      });
  };

  const getAccountBalance = () => {
    handleSpringDataRequest(
      `core/api/v1/account-ledger/get-account-balance?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setAccBalance(res?.message);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        toast.error(err);
      });
  };

  useEffect(() => {
    groupId && getLedgerData();
    groupId && getAccountBalance();
  }, [groupId]);

  const [mykeys, setmykeys] = useState(1);
  const [bgcolors, setbgcolors] = useState("");

  function mycall(e) {
    setmykeys(e);
  }
  return (
    <div>
      {/* <div style={{ width: "13%" }}>
        {Sidebar.map((item, key) => (
          <MenuItem call={mycall} value={mykeys} key={key} item={item} />
        ))}
      </div>
      <div style={{ width: "87%", marginLeft: "2%" }}>
        {mykeys === 1 && ( */}
      <Transactions
        ledger={ledger}
        getAccountBalance={getAccountBalance}
        accBalance={accBalance}
        groupId={groupId}
        getLedgerData={getLedgerData}
        res={accountInfo}
        paynow={props.paynow}
        showAutoPay={props.showAutoPay}
      />
      {/* )} */}
      {/* {mykeys === 2 && <Documents />}
        {mykeys === 3 && <Transactions />}
        {mykeys === 4 && <Documents />}
        {mykeys === 5 && <Transactions />} */}
      {/* {mykeys === 6 && <CTA />}
        {mykeys === 7 && <Promocodes />}
        {mykeys === 8 && <RateShopper />} */}
      {/* </div> */}
    </div>
  );
}

// const MenuItem = ({ item, value, call }) => {
//   const Component = hasChildren(item) ? MultiLevel : SingleLevel;
//   return <Component value={value} call={call} item={item} />;
// };

// const SingleLevel = ({ item, value, call }) => {
//   const myClick = () => {
//     call(item.mykey);
//   };
//   return (
//     <ListItem button onClick={myClick}>
//       {/* <ListItemIcon sx={{ color: item.mykey === value ? "#1BC5BD" : "" }}>
//         {item.icon}
//       </ListItemIcon> */}
//       <ListItemText
//         sx={{ color: item.mykey === value ? "#1BC5BD" : "" }}
//         primary={item.title}
//       />
//     </ListItem>
//   );
// };

// const MultiLevel = ({ item, value, call }) => {
//   const { items: children } = item;
//   const [open, setOpen] = useState(false);

//   const handleClick = () => {
//     call(item.mykey);
//     setOpen((prev) => !prev);
//   };

//   return (
//     <div>
//       <ListItem button onClick={handleClick}>
//         {/* <ListItemIcon sx={{ color: item.mykey === value ? "#1BC5BD" : "" }}>
//           {item.icon}
//         </ListItemIcon> */}
//         <ListItemText
//           // sx={{ color: item.mykey === value ? "#1BC5BD" : "" }}
//           primary={item.title}
//         />
//         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//       </ListItem>
//       <Collapse in={open} timeout="auto" unmountOnExit>
//         <List
//           component="div"
//           disablePadding
//           style={{ marginLeft: "2rem" }}
//           // sx={{ color: item.mykey === value ? "#1BC5BD" : "" }}
//         >
//           {children.map((child, key) => (
//             <MenuItem call={call} key={key} item={child} />
//           ))}
//         </List>
//       </Collapse>
//     </div>
//   );
// };
