// ** React Imports
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Divider,
  Chip,
  MenuList,
  Paper,
  Popper,
  Grow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import Mike from "@mui/material/Modal";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { connect } from "react-redux";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import {
  getuserBookingImages,
  updateEnquiryStatus,
} from "../../BookingDashboard/apis/BookingDashboardApi";
import {
  addNewBookingNote,
  deleteTheBookingNotes,
  getAllBookingNotes,
} from "../api/BookingAndCustomerNotesApi";
import UnblockView from "./UnblockView";
import { Link } from "react-router-dom";
import {
  getCurrencySymbol,
  getTimestamp,
  validateCheckin,
} from "../../../Utils/Utils";
import ClickAwayListenerHelper from "../../../Utils/ClickAwayListenerHelper";
import axios from "axios";
import { subtract } from "lodash-es";
import CardforButtons from "./CardforButtons.js";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import PrintEmptyResCard from "../../Folios/PrintEmptyResCard";
import PrintResCard from "../../Folios/PrintResCard";
import { getAllImagesAndUrl } from "../../BookingDashboard/apis/BrandingModalApi";
import i18n from "../../../modules/Auth/pages/i18n.js";
import { useTranslation } from "react-i18next";
import PrintVehicleResCard from "../../Folios/PrintVehicleResCard.js";
import PrintEmptyVehicleResCard from "../../Folios/PrintEmptyVehicleResCard.js";
import MockLoaderTable from "../../Folios/ViewManagePayments/MockLoaderTable.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  displayHeading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1),
    wordBreak: "break-word",
  },
}));

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 0,
  zIndex: 1,
  border: "1px solid",
  p: 1,
  bgcolor: "background.paper",
  width: "13rem",
};

const CustomerInformation = (props) => {
  const {
    reservationData,
    eventData,
    reload,
    viewFolio,
    closeBackDrop,
    hotelType,
    resId,
    fetchReservationData,
    selectedReservationData,
    allGuestList,
    isNightAuditOn,
    subUser,
    setMenuValue,
    handleOpenReleaseInventoryModal,
    handleOpenRollbackBookings,
    selectedLanguage,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  function ReadMore({ children = 100 }) {
    const text = children;

    const [isShow, setIsShowLess] = useState(true);

    const result = isShow ? text.slice(0, 100) : text;

    function toggleIsShow() {
      setIsShowLess(!isShow);
    }

    return (
      <p>
        {result}
        <span className="btn btn-link p-0" onClick={toggleIsShow}>
          {isShow ? `${t("Read More")}` : `${t("Read Less")}`}
        </span>
      </p>
    );
  }

  const [hotelemail, sethotelemail] = useState(
    hotel?.accessControl?.hotel?.hotel_email
  );
  const [checkinEpoch, setcheckinEpoch] = useState(
    moment(reservationData.booking_checkin).unix()
  );

  const loggedUserEmail = localStorage.getItem("email");
  const [checkoutEpoch, setcheckoutEpoch] = useState(
    moment(reservationData.booking_checkout).unix()
  );
  const [offlineCardPayLoading, setOfflineCardPayLoading] = useState(false);
  const [offlineCardDate, setOfflineCardDate] = useState(new Date());
  const [currentEpoch, setcurrentEpoch] = useState(0);
  const [serviceAmountDesc, setServiceAmountDesc] = useState();
  const [offlineCardDesc, setOfflineCardDesc] = useState();
  const currency = hotel.accessControl.hotel.hotel_currency;
  const classes = useStyles();
  const date2 = new Date();
  const [amount, setAmount] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  // cheque pay loading
  const [offlineCheckPayLoading, setOfflineCheckPayLoading] = useState(false);
  const [offlineCardAmt, setOfflineCardAmt] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  const [offlineCheckDate, setOfflineCheckDate] = useState(new Date());
  const [offlineCashDate, setOfflineCashDate] = useState(new Date());
  const [chequeDesc, setChequeDesc] = useState();

  const [openOfflineCheckPay, setOpenOfflineCheckPay] = useState(false);
  const handleOpenOfflineCheckPay = () => setOpenOfflineCheckPay(true);
  const handleCloseOfflineCheckPay = () => setOpenOfflineCheckPay(false);
  const [showMore, setshowMore] = useState(false);
  const [loader, setloader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setloader(false);
    }, 2000); // 2 seconds

    // Clean up the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  // fields for for offline cheque payment

  const [offlineCheckAmt, setOfflineCheckAmt] = useState(
    reservationData?.balance_due
  );

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const [openModal, setOpenModal] = useState(false);

  const openFunc = (status) => {
    if (
      JSON.parse(hotel?.accessControl?.user_feature_map?.PastDateCheckin)
        .read !== "YES" &&
      checkinEpoch < currentEpoch &&
      status === "checkin"
    ) {
      toast.error(`${t("Checkin is not allowed for past date")}`);
    } else if (
      JSON.parse(hotel?.accessControl?.user_feature_map?.PastDateCheckout)
        .read !== "YES" &&
      checkoutEpoch < currentEpoch &&
      status === "checkout"
    ) {
      toast.error(`${t("Checkout is not allowed for past date")}`);
    } else {
      setOpenModal(true);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  //Booking Notes
  const [bookingNotes, setbookingNotes] = useState([]);
  const [loading, setloading] = useState(false);
  const [bookingNoteData, setbookingNoteData] = useState("");
  const [remark, setremark] = useState("");

  useEffect(async () => {
    await getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const data = await getAllBookingNotes(reservationData.booking_id);
      setbookingNotes(data);
    } catch (e) {
      console.error(e);
    }
  };
  const Confirmed = async () => {
    setloading(true);
    const payload = {
      id: reservationData.booking_id,
    };
    try {
      await updateEnquiryStatus(payload);
      toast.success(`${t("Successfully confirmed booking")}`);
      setloading(false);
    } catch (e) {
      setloading(false);
      toast.error(`${t("Something went wrong")}`);
    }

    // window.location.reload();
    reload();
  };
  const createBookingNote = async () => {
    const payload = {
      hotelId: hotel.hotelId,
      bookingID: reservationData.booking_id,
      note: bookingNoteData,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewBookingNote(payload);

    await getAllData();
    handleClose();
  };

  const deleteBookingNote = async (id, notes) => {
    const data = await deleteTheBookingNotes(
      reservationData.booking_id,
      id,
      localStorage.getItem("email"),
      notes
    );

    getAllData();
  };

  //Add Booking Note Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Add Booking Note Modal
  const [showTagModal, setshowTagModal] = useState(false);

  const handleCloseTagModal = () => setshowTagModal(false);
  const handleShowTagModal = () => setshowTagModal(true);

  // tag modal apis
  const [allHotelTags, setallHotelTags] = useState([]);
  const [allBookingTags, setallBookingTags] = useState([]);
  const [bookingRemark, setbookingRemark] = useState("");
  const handleCloseCashPayment = () => setOpenCashPayment(false);
  // const [selectedBookingTags, setselectedBookingTags] = useState([]);

  const handleSelectedBookingTags = (name) => {
    // console.log(name, allBookingTags.indexOf(name));
    var idx = allBookingTags.indexOf(name);
    if (idx !== -1) {
      const temp = allBookingTags.filter((item) => item !== name && item);
      // console.log("temp : ", temp);
      return setallBookingTags(temp);
    } else {
      setallBookingTags([...allBookingTags, name]);
    }
  };

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/booking-tags/get-all?bookingId=${reservationData?.booking_id}`
    )
      .then((res) => {
        setallBookingTags(res[0]?.tags?.split(","));
        setbookingRemark(res[0]?.remark);
        // const data = res[0].tags.split(",");
        // console.log("allbokking tags", data);
        // setselectedBookingTags(data);
      })
      .catch((err) => console.error(`${t("Error in fetching tags")}`));

    handleSpringDataRequest(`core/api/v1/hotel-booking-tags/get-all`)
      .then((res) => {
        const data = res.filter((item) => item.status === "ACTIVE");
        // console.log("all hotel tags", data);
        setallHotelTags(data);
      })
      .catch((err) => console.error(`${t("Error in fetching tags")}`));
  }, []);

  const [frontUrl, setfrontUrl] = useState("");
  const [backUrl, setbackUrl] = useState("");
  useEffect(async () => {
    const data = await getuserBookingImages(reservationData.booking_id);
    setfrontUrl(data.frontUrl);
    setbackUrl(data.backUrl);
  }, []);

  // Magic link modal
  const [openMagicLinkModal, setOpenMagicLinkModal] = useState(false);
  const handleOpenMagicLinkModal = () => {
    setOpenMagicLinkModal(true);
  };

  const handleCloseMagicLinkModal = () => {
    setOpenMagicLinkModal(false);
  };

  const envType = process.env.REACT_APP_ENV;

  // handling booking confirmation email loading
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  // pre populating magic link modal fields
  const [magicLinkMail, setMagicLinkMail] = useState(
    reservationData.user_email
  );
  const [magicLinkPhone, setMagicLinkPhone] = useState(
    reservationData.user_contact
  );
  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  function handleSendMagicLink() {
    if (!validateEmail(magicLinkMail)) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail.includes("example.com")) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    setMagicLinkLoading(true);
    if (magicLinkMail == "" || magicLinkPhone == "") {
      toast.error(
        `${t("Empty email or phone, please enter valid email and phone.")}`
      );
      setMagicLinkLoading(false);
      return;
    }
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendSelfServiceLinkCust/",
      {
        bookingId: reservationData.booking_id,
        email: magicLinkMail,
        phoneNumber: magicLinkPhone,
      }
    )
      .then((res) => toast.success(`${t("Magic link sent successfully.")}`))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseMagicLinkModal())
      .then((res) => setMagicLinkMail(reservationData.user_email))
      .then((res) => setMagicLinkPhone(reservationData.user_contact))
      .catch((err) => toast.error(err))
      .catch((err) => setMagicLinkLoading(false));
  }

  const updateBookingTags = () => {
    const payload = {
      id: "",
      bookingId: reservationData.booking_id,
      tags: allBookingTags.toString(),
      remark: remark,
    };
    handleSpringDataMutationRequest(
      "PUT",
      `core/api/v1/booking-tags/update`,
      payload
    )
      .then((res) => {
        toast.success(`${t("Successfully added tag")}`);
        setremark("");
        handleCloseTagModal();
      })
      .catch((err) => {
        toast.error(err.response);
        setremark("");
        handleCloseTagModal();
      });
  };

  // Validate if the checkin must be allowed
  const [checkinHours, setCheckinHours] = useState(9);
  const handleCheckinHours = () =>
    handleSpringDataRequest("core/api/v1/pc/get-checkin-hours")
      .then((res) =>
        setCheckinHours(res ? Number(res.hours_before_checkin) : checkinHours)
      )
      .catch((err) =>
        toast.error(`${t("Error in fetching the checkin hours")}`)
      );

  useEffect(() => {
    handleCheckinHours();
  }, []);

  const hours_start =
    getTimestamp(
      moment(eventData.event.extendedProps.actual_start).format(
        "YYYY-MM-DDTHH:mm:ss"
      )
    ) -
    Number(checkinHours) * 3600000;
  const hours_end = getTimestamp(
    moment(eventData.event.extendedProps.actual_end).format(
      "YYYY-MM-DDTHH:mm:ss"
    )
  );

  useEffect(() => {
    handleSpringDataRequest("web-api/get-today-hotel-epoch")
      .then((res) => setcurrentEpoch(res))
      .catch((err) => console.log(err));
  }, []);

  // confirmation modal for handling the rollback of booking
  const [openRollbackBooking, setOpenRollbackBooking] = useState(false);
  const handleOpenRollbackBooking = () => setOpenRollbackBooking(true);
  const handleCloseRollbackBooking = () => setOpenRollbackBooking(false);
  const [rollbackLoader, setRollbackLoader] = useState(false);

  const handleCloseOfflineCardPay = () => {
    setOpenOfflineCardPay(false);
  };

  const handleRollbackBooking = (bookingId) => {
    setRollbackLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/rollback-checkin-booking`,
        {
          bookingId: bookingId,
          resIds: [],
        }
      )
      // .then((res) =>
      //   toast.success("Status is changed to CONFIRMED successfully.")
      // )
      .then((res) => setRollbackLoader(false))
      .then((res) => handleCloseRollbackBooking())
      .then(() => toast.success(`Successfully rolled back ${bookingId}`))
      .then((res) => {
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((err) =>
        toast.error(`${t("Error in changing the status to CONFIRMED.")}`)
      );
  };

  // accepting cash payment modal
  const [openCashPayment, setOpenCashPayment] = useState(false);
  const handleOpenCashPayment = () => {
    if (
      hotelType === "ISHA" &&
      Number(reservationData.total_amount_with_services).toFixed(2) > 100000
    ) {
      toast.error(
        `${t("Cannot take cash transaction of more than 100000 in cash")}`
      );
    } else {
      if (hotelType === "ISHA") {
        toast.error(
          `${t(
            "Please ensure to preview the E-Receipt before accepting the payment"
          )}`
        );
      }
      setOpenCashPayment(true);
    }
  };

  //call to create custom invoice
  const callInvoiceIdGeneration = () => {
    axios.get(
      `${process.env.REACT_APP_SPRING_API_URL}web-api/generate-custom-invoice-id?bookingId=${reservationData?.booking_id}&bookingStatus=${reservationData?.booking_status}`
    );
  };

  // handling the format in which data is being sent
  const handleSentDateFormat = (recordDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(recordDate).format("YYYY-MM-DD")
    ) {
      return moment(recordDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(recordDate).format("YYYY-MM-DD") + " 00:00:00";
  };

  // cash payment loading
  const [cashPayLoading, setCashPayLoading] = useState(false);

  const handleCashPayment = () => {
    setCashPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordCashPayment/`,
        {
          hotelId: hotel.hotelId,
          bookingID: reservationData.booking_id,
          reservation_id: "",
          amount: amount,
          offline_card_date: handleSentDateFormat(offlineCashDate),
          service_amount_desc: serviceAmountDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Successfully recorded cash payment")} ${
            reservationData?.booking_id
          }`
        )
      )
      .then((res) => fetchReservationData())
      .then((res) => setCashPayLoading(false))
      .then((res) => setAmount(""))
      .then((res) => callInvoiceIdGeneration())
      .then((res) => setServiceAmountDesc())
      .then((res) => handleCloseCashPayment())
      .catch((error) => setCashPayLoading(false))
      .catch((error) => handleCloseCashPayment())
      .catch((error) => toast.error(error));
  };

  // UPI payment modal loading
  const [openUpi, setOpenUpi] = useState(false);
  const handleOpenUpiPay = () => setOpenUpi(true);
  const handleCloseUpiPay = () => setOpenUpi(false);
  const [upiPayLoading, setUpiPayLoading] = useState(false);

  const [upiDate, setUpiDate] = useState(new Date());

  const [upiAmt, setUpiAmt] = useState(reservationData?.balance_due);
  const [upiDesc, setUpiDesc] = useState("");

  const handleUpiPayment = () => {
    setUpiPayLoading(true);
    axios
      .post(`${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordUPIPayment/`, {
        hotelId: hotel?.hotelId,
        bookingID: reservationData?.booking_id,
        reservation_id: "",
        amount: Number(upiAmt),
        offline_upi_date: handleSentDateFormat(upiDate),
        service_amount_desc: upiDesc,
        logged_user_email: loggedUserEmail,
      })
      // .then((res) => updateSummaryDataFromCache())
      .then((res) =>
        toast.success(
          `${t("Successfully recorded UPI payment")} ${
            reservationData?.booking_id
          }`
          // `UPI payment of ${upiAmt} recorded for ${reservationData?.booking_id}!`
        )
      )
      .then((res) => fetchReservationData())
      .then((res) => setUpiPayLoading(false))
      .then((res) => setUpiAmt(""))
      .then((res) => setUpiDate(new Date()))
      .then((res) => setUpiDesc(""))
      .then((res) => callInvoiceIdGeneration())
      .then((res) => handleCloseUpiPay())
      .catch((err) => toast.error(err))
      .then((err) => setUpiPayLoading(false))
      .then((err) => handleCloseUpiPay());
  };

  // Other payment mode modal loading
  const [openOtherModePay, setOpenOtherModePay] = useState(false);
  const handleOpenOtherModePay = () => setOpenOtherModePay(true);
  const handleCloseOtherModePay = () => setOpenOtherModePay(false);
  const [otherModePayLoading, setOtherModePayLoading] = useState(false);

  // Bank transfer payment mode modal
  const [openBankTransferModePay, setOpenBankTransferModePay] = useState(false);
  const handleOpenBankTransferModePay = () => setOpenBankTransferModePay(true);
  const handleCloseBankTransferModePay = () =>
    setOpenBankTransferModePay(false);

  const [bankTransferModePayLoading, setBankTransferModePayLoading] =
    useState(false);

  const [bankTransferModeDate, setBankTransferModeDate] = useState(new Date());

  const [bankTransferModeAmt, setBankTransferModeAmt] = useState(
    reservationData?.balance_due
  );
  const [bankTransferModeDesc, setBankTransferModeDesc] = useState("");

  const [otherModeDate, setOtherModeDate] = useState(new Date());

  const [otherModeAmt, setOtherModeAmt] = useState(
    reservationData?.balance_due
  );
  const [otherModeDesc, setOtherModeDesc] = useState("");

  const handleBankTransferPayment = () => {
    setBankTransferModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordBankTransferPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: bankTransferModeAmt,
          bank_transfer_date: handleSentDateFormat(bankTransferModeDate),
          service_amount_desc: bankTransferModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Successfully recorded bank transfer")} ${
            reservationData?.booking_id
          }`
          // `Bank transfer payment of ${bankTransferModeAmt} recorded for ${reservationData?.booking_id}!`
        )
      )

      .then((res) => fetchReservationData())
      .catch((err) => toast.error(err))
      .finally(() => setBankTransferModeAmt(""))
      .finally(() => setBankTransferModeDate(new Date()))
      .finally(() => setBankTransferModeDesc(""))
      .then((res) => callInvoiceIdGeneration())
      .finally(() => handleCloseBankTransferModePay())
      .finally(() => setBankTransferModePayLoading(false));
  };

  const handleOtherModePayment = () => {
    setOtherModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOtherPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: otherModeAmt,
          other_payment_date: handleSentDateFormat(otherModeDate),
          service_amount_desc: otherModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Successfully recorded other payment mode")} ${
            reservationData?.booking_id
          }`
          // `Other payment of ${otherModeAmt} recorded for ${reservationData?.booking_id}!`
        )
      )
      .then((res) => fetchReservationData())
      .then((res) => setOtherModePayLoading(false))
      .then((res) => setOtherModeAmt(""))
      .then((res) => setOtherModeDate(new Date()))
      .then((res) => setOtherModeDesc(""))
      .then((res) => handleCloseOtherModePay())
      .then((res) => callInvoiceIdGeneration())
      .catch((err) => setOtherModePayLoading(false))
      .catch((err) => handleCloseOtherModePay())
      .catch((err) => toast.error(err));
  };

  // paymentLink modal

  const [openSendPaymentLink, setOpenSendPaymentLink] = useState(false);
  const handleOpenSendPaymentLink = () => setOpenSendPaymentLink(true);
  const handleCloseSendPaymentLink = () => setOpenSendPaymentLink(false);

  // cash deposit modal

  const [openCashDeposit, setOpenCashDeposit] = useState(false);
  const handleOpenCashDeposit = () => setOpenCashDeposit(true);
  const handleCloseCashDeposit = () => setOpenCashDeposit(false);

  // fields for for cash deposit

  const [depositAmt, setDepositAmt] = useState(0);
  const [depositDate, setDepositDate] = useState(new Date());
  const [depositName, setDepositName] = useState();

  // deposit loading
  const [depositLoading, setDepositLoading] = useState(false);

  // handling cash deposit date format
  const handleOpenCashDepositDate = (cashDepositDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(cashDepositDate).format("YYYY-MM-DD")
    ) {
      return moment(cashDepositDate).format("MMM DD YYYY HH:mm:ss");
    }
    return moment(cashDepositDate).format("MMM DD YYYY") + " 00:00:00";
  };

  // handling the cash deposit

  const handleDeposit = () => {
    setDepositLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/addServiceToInvoice/`,
        {
          hotelId: hotel?.hotelId,
          booking_id: reservationData?.booking_id,
          reservation_id: "",
          service_item: {
            service_date: handleOpenCashDepositDate(depositDate),
            service_amount: depositAmt,
            service_desc: depositName,
            service_amount_type: "DEPOSIT",
          },
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Successfully recorded deposit")} ${reservationData?.booking_id}`
        )
      )
      .then((res) => fetchReservationData())
      .then((res) => setDepositLoading(false))
      .then((res) => setDepositAmt(""))
      .then((res) => setDepositDate(new Date()))
      .then((res) => setDepositName())
      .then((res) => handleCloseCashDeposit())
      .catch((error) => setDepositLoading(false))
      .catch((error) => handleCloseCashDeposit())
      .catch((error) => toast.error(error));
  };

  // accepting offline card payment modal

  const [openOfflineCardPay, setOpenOfflineCardPay] = useState(false);
  const handleOpenOfflineCardPay = () => {
    if (hotelType === "ISHA") {
      toast.error(
        `${t(
          "Please ensure to preview the E-Receipt before accepting the payment"
        )}`
      );
    }
    setOpenOfflineCardPay(true);
  };

  const handleOfflineCardPayment = () => {
    const payload = {
      hotelId: hotel?.hotelId,
      bookingID: reservationData.booking_id,
      reservation_id: "",
      amount: offlineCardAmt,
      offline_card_date: handleSentDateFormat(offlineCardDate),
      service_amount_desc: offlineCardDesc,
      logged_user_email: loggedUserEmail,
    };

    setOfflineCardPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCardPayment/`,
        payload
      )
      .then((res) =>
        toast.success(
          `${t("Successfully recorded card payment")} ${
            reservationData?.booking_id
          }`
          // `Card payment of ${offlineCardAmt} recorded for ${reservationData.booking_id}!`
        )
      )
      .then((res) => fetchReservationData())
      .then((res) => setOfflineCardPayLoading(false))
      .then((res) => setOfflineCardAmt(""))
      .then((res) => setOfflineCardDate(new Date()))
      .then((res) => setOfflineCardDesc())
      .then((res) => callInvoiceIdGeneration())
      .then((res) => handleCloseOfflineCardPay())
      .catch((err) => setOfflineCardPayLoading(false))
      .catch((err) => handleCloseOfflineCardPay())
      .catch((err) => toast.error(err));
  };

  // handling the offline cheque payment flows

  const handleOfflineCheckPayment = () => {
    setOfflineCheckPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCheckPayment/`,
        {
          hotelId: hotel?.hotelId,
          booking_id: reservationData?.booking_id,
          reservation_id: "",
          amount: offlineCheckAmt,
          offline_card_date: handleSentDateFormat(offlineCardDate),
          logged_user_email: loggedUserEmail,
          service_amount_desc: chequeDesc,
        }
      )
      .then((res) =>
        toast.success(
          // `Offline cheque payment of ${offlineCheckAmt} recorded`
          `${t("Successfully recorded cheque payment")} ${
            reservationData?.booking_id
          }`
        )
      )
      .then((res) => fetchReservationData())
      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc())
      .then((res) => callInvoiceIdGeneration())
      .then((res) => handleCloseOfflineCheckPay())
      .catch((error) => toast.error(error))
      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => handleCloseOfflineCheckPay())
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc());
  };

  // paymentLink loading
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
  const [paymentLinkEmail, setPaymentLinkEmail] = useState(
    reservationData?.user_email
  );
  const [paymentLinkMobile, setPaymentLinkMobile] = useState(
    selectedReservationData?.user_contact
  );
  const [paymentLinkAmt, setPaymentLinkAmt] = useState(
    reservationData?.balance_due
  );

  // sending customer payment link
  function handlePaymentLink() {
    setPaymentLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendInvoicePaymentCustEmail/",
      {
        hotel_id: hotel?.hotelId,
        booking_id: reservationData?.booking_id,
        reservation_id: null,
        email: paymentLinkEmail,
        phone: paymentLinkMobile,
        amount: paymentLinkAmt,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) => toast.success(`${t("Successfully sent payment link")}`))
      .then((res) => fetchReservationData())
      .then((res) => setPaymentLinkLoading(false))
      .then((res) => handleCloseSendPaymentLink())
      .catch((err) => setPaymentLinkLoading(false))
      .catch((err) => toast.error(err));
  }

  const [showCheckout, setshowCheckout] = useState(false);

  useEffect(() => {
    let checkedOut = 0;
    let chekcedIn = 0;
    const data = allGuestList.filter((item) => item.reservationId === resId);
    // if (checkedIn >= allGuestList.length - 1) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].guest_status === "ADMIN_CONFIRMED") {
        chekcedIn = chekcedIn + 1;
      } else if (data[i].guest_status === "CHECKED_OUT") {
        checkedOut = checkedOut + 1;
      }
    }

    if (chekcedIn === 1 && checkedOut === data.length - 1) {
      setshowCheckout(true);
    } else {
      setshowCheckout(false);
    }
  }, [allGuestList]);

  const [showCheckinButton, setshowCheckinButton] = useState(false);

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/get-night-audit-flag?currentDate=${moment(
        reservationData.booking_checkin.replace("T", " "),
        "YYYY-MM-DD HH:mm:ss"
      )
        .subtract(1, "days")
        .format("DD-MM-YYYY")}&opType=WALK_IN`
    )
      .then((res) => {
        if (
          props?.data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
          props?.data?.accessControl?.ADMINRIGHTS === "SUBADMIN"
        ) {
          setshowCheckinButton(true);
        } else {
          setshowCheckinButton(res);
        }
      })
      .catch((err) => console.log(err));
  }, [reservationData.booking_checkin]);

  /* PRINT RESERVATION */
  // Modal for printing Registration card
  // Giving an option to print normal Registration card or empty res card for hotels to fill out the details
  const [openResCard, setOpenResCard] = useState(false);
  const handleOpenResCard = () => setOpenResCard(true);
  const handleCloseResCard = () => setOpenResCard(false);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      width: "50vw",
      backgroundColor: "#fff",
      height: "100%",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });

  // Checkbox to determine whether to print with details of fill up the details on own
  const [manualResCard, setManualResCard] = useState(false);
  const handleChangeManualResCard = (e) => {
    setManualResCard(e.target.checked);
  };

  const [defaultCtadropdown, setDefaultdropdown] = useState([]);
  // handling the CTA dropdowns
  const [ctaDropdown, setCtaDropdown] = useState([]);
  function handleCtaDropdown() {
    // handleTrueWrapperMockLoader();
    setCtaDropdown([]);
    setDefaultdropdown([]);
    // if (Number(summaryData?.total_cta_balance) === 0) {
    // setCtaDropdown([
    //   {
    //     ctaName: "Walk-In",
    //     taxId: null,
    //   },
    // ]);
    djangoHandleDataRequests("invoice/getBookingSrcDropdownData/")
      .then((res) => {
        setDefaultdropdown(res);
        Number(summaryData?.total_cta_balance) === 0 && setCtaDropdown(res);
      })

      .catch((err) => toast.error(`${t("Error fethcing cta dropdown.")}`));
  }

  useEffect(() => {
    if (
      reservationData?.booking_id !== null &&
      reservationData?.booking_id !== undefined &&
      reservationData?.booking_id !== ""
    ) {
      handleGetSummaryDataWithCache();
      handleCtaDropdown();
      getData();
      getCustomFolioConfig();
      getCompanyDetails();
    }
  }, [reservationData?.booking_id]);

  const [invoiceData, setInvoiceData] = useState(null);

  // Get custom folio config
  const [customFolioConfig, setCustomFolioConfig] = useState(null);
  const getCustomFolioConfig = () => {
    djangoHandleDataRequests("invoice/getCustomFolioConfig/")
      .then((res) => setCustomFolioConfig(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) =>
        toast.error(`${t("Error getting the folio config data")}`)
      );
  };

  const [allCompanyDetails, setallCompanyDetails] = useState([]);

  const getCompanyDetails = () => {
    handleSpringDataRequest(`core/api/v1/guest-company-details/get-all`)
      .then((res) => setallCompanyDetails(res))
      .catch((err) => console.error(err));
  };

  // Getting the invoice data for the first time ---  from the cache
  const getData = () => {
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-data-render/?bookingId=${reservationData?.booking_id}&fromDb=false`
    )
      .then((res) => {
        setInvoiceData(res);
      })
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(`${t("Error fetching the details")}`));
  };

  // Get the data for invoice summary
  const [summaryData, setSummaryData] = useState(null);
  const handleGetSummaryDataWithCache = () => {
    setSummaryData(null);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-summary-data/?bookingId=${reservationData?.booking_id}&fromDB=false`
    )
      .then((res) => setSummaryData(res))

      // .then((res) => fetchBookingData())
      .catch((err) =>
        toast.error(`${t("Failed in getting the payment summary")}`)
      );
  };

  // Getting the hotel logo
  const [hotelLogo, sethotelLogo] = useState(null);
  const handleBase64Img = () => {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => sethotelLogo(res))
      .catch((err) => toast.error(`${t("Error in fethcing the hotel logo!")}`));
  };

  // Getting the hotel logo
  useEffect(() => {
    summaryData && invoiceData && hotelLogo === null && handleBase64Img();
  }, [summaryData, invoiceData, hotelLogo, hotel.hotelId]);

  // console.log("reservationData", reservationData);

  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();

      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row" className={classes.textField}>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            {t("Booking Id")}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.booking_id}
          </Typography>
        </Grid>
        {reservationData?.booking_source && (
          <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography className={classes.displayHeading}>
              {t("Booking source")}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {reservationData?.booking_source === "STAYFLEXI_HD"
                ? `${t("Walk In")}`
                : reservationData?.booking_source === "STAYFLEXI_OD"
                ? `${t("Booking Engine")}`
                : reservationData?.booking_source}
            </Typography>
          </Grid>
        )}

        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            {t("Guest name")}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.user_name}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            {t("Guest phone")}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.user_contact}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            {t("Room type")}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.room_type_name}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            {t("Rate plan")}{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.rate_plan_name}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            {t("Final amount with tax")}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(
              reservationData.total_amount_with_services
            ).toFixed(2)}`}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            {t("Payment made")}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(reservationData.payment_made).toFixed(2)}`}
          </Typography>
        </Grid>
        {showMore && (
          <>
            <Grid
              container
              whiteSpace={"nowrap"}
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
            >
              <Typography className={classes.displayHeading}>
                {t("Check-In")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {" "}
                {moment(
                  reservationData.booking_checkin.replace("T", " "),
                  "YYYY-MM-DD HH:mm:ss"
                ).format("MMM Do YYYY, h A")}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Check-out")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {moment(
                  reservationData.booking_checkout.replace("T", " "),
                  "YYYY-MM-DD HH:mm:ss"
                ).format("MMM Do YYYY, h A")}
              </Typography>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Booking made on")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {moment(
                  reservationData.booking_made_on,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("MMM Do YYYY, hh:mm A")}
              </Typography>
            </Grid>

            {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>Room type </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.room_type_name}
          </Typography>
        </Grid> */}
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Adults / Children / Infant")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {reservationData.adults} / {reservationData.children} /{" "}
                {reservationData.children1}
              </Typography>
            </Grid>
            {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>Children</Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.children}
          </Typography>
        </Grid> */}
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Booked Room No.(s)")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {reservationData.reservation_rooms}
              </Typography>
            </Grid>
            {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Payment made{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(reservationData.payment_made).toFixed(2)}`}
          </Typography>
        </Grid> */}
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Room Booking Amount")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {`${currency} ${Number(
                  reservationData.total_pretax_amount_with_services
                ).toFixed(2)}`}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Room Booking Tax")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {`${currency} ${Number(
                  reservationData.total_room_tax_amount
                ).toFixed(2)}`}
              </Typography>
            </Grid>

            {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Balance Due{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(reservationData.balance_due).toFixed(2)}`}
          </Typography>
        </Grid> */}
            {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Tax percentage{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {Number(reservationData.tax_percentage).toFixed(2)}
          </Typography>
        </Grid> */}

            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Addons Amount")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {`${currency} ${Number(
                  reservationData.total_services_pretax_amount
                ).toFixed(2)}`}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Addons Tax")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {`${currency} ${Number(
                  reservationData.total_services_tax
                ).toFixed(2)}`}
              </Typography>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Total Tax")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {`${currency} ${Number(
                  reservationData.total_tax_amount
                ).toFixed(2)}`}
              </Typography>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Balance Due")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {`${currency} ${Number(reservationData.balance_due).toFixed(
                  2
                )}`}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography className={classes.displayHeading}>
                {t("Access code")}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {reservationData.seam_access_code === ""
                  ? "NA"
                  : reservationData.seam_access_code}
              </Typography>
            </Grid>
            {reservationData?.ota_booking_id !== null && (
              <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography className={classes.displayHeading}>
                  {t("OTA Booking Id")}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {reservationData?.ota_booking_id === undefined
                    ? "NA"
                    : reservationData?.ota_booking_id}
                </Typography>
              </Grid>
            )}

            {JSON.parse(
              props?.data?.accessControl?.user_feature_map?.EnquiryDashboard
            ).read === "YES" && (
              <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography className={classes.displayHeading}>
                  {t("Tags")}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {allBookingTags?.toString()}
                </Typography>
              </Grid>
            )}
            {JSON.parse(
              props?.data?.accessControl?.user_feature_map?.EnquiryDashboard
            ).read === "YES" && (
              <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography className={classes.displayHeading}>
                  {t("Remark")}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {bookingRemark}
                </Typography>
              </Grid>
            )}

            {reservationData?.special_requests !== "" && (
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className={classes.displayHeading}>
                  {t("Special request")}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  <ReadMore> {reservationData?.special_requests} </ReadMore>
                </Typography>
              </Grid>
            )}

            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.displayHeading}>
                {reservationData.seam_access_code === "" ? "" : ""}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.displayHeading}>
                {reservationData.seam_access_code === null ? "" : ""}
              </Typography>
            </Grid>
          </>
        )}
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <p
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#1BC5BD",
              fontSize: "15px",
              marginBottom: "0px",
              fontWeight: "bold",
            }}
            onClick={() => setshowMore(!showMore)}
          >{`${showMore ? `${t("Read less")}` : `${t("Read more")}`}`}</p>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}></Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}></Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </Grid>

        {loader === false ? (
          <Grid
            item
            className={classes.textField}
            container
            xs={12}
            spacing={2}
          >
            {reservationData?.is_enquiry === "false" ? (
              validateCheckin(hours_start, hours_end) &&
              eventData.event.extendedProps.status === "CONFIRMED" &&
              showCheckinButton && (
                <Grid item>
                  {JSON.parse(
                    props?.data?.accessControl?.user_feature_map?.CheckIn
                  ).read === "YES" && (
                    <div onClick={() => openFunc("checkin")}>
                      <CardforButtons
                        heading={t("Check-in")}
                        variant="custom-button"
                        selectedLanguage={selectedLanguage}
                      ></CardforButtons>
                    </div>
                  )}
                </Grid>
              )
            ) : (
              <Grid item>
                {loading ? (
                  <CircularProgress
                    size={35}
                    color="inherit"
                    style={{ marginLeft: "2rem", marginTop: "1rem" }}
                  />
                ) : (
                  <div onClick={Confirmed}>
                    <CardforButtons
                      heading={t("Confirm booking")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                )}
              </Grid>
            )}
            {showCheckout &&
              JSON.parse(props?.data?.accessControl?.user_feature_map?.CheckOut)
                .read === "YES" &&
              JSON.parse(
                props?.data?.accessControl?.user_feature_map
                  ?.GuestCheckInCheckOut
              ).read === "YES" && (
                <Grid item>
                  {Number(reservationData.balance_due) > 0 ? (
                    <Link
                      to={{
                        pathname: `folio/${eventData.event.extendedProps.bookingId}`,
                        search: `&hotelId=${hotel.hotelId}`,
                      }}
                    >
                      <CardforButtons
                        heading={t("Checkout")}
                        variant="custom-button"
                        selectedLanguage={selectedLanguage}
                      ></CardforButtons>
                    </Link>
                  ) : (
                    <div onClick={() => openFunc("checkout")}>
                      <CardforButtons
                        heading={t("Checkout")}
                        variant="custom-button"
                        selectedLanguage={selectedLanguage}
                      ></CardforButtons>
                    </div>
                  )}
                </Grid>
              )}
            {selectedReservationData?.booking_status === "CONFIRMED" && (
              <Grid item>
                <div onClick={handleOpenResCard}>
                  <CardforButtons
                    heading={t("Print Reg. Card")}
                    variant="custom-button"
                    selectedLanguage={selectedLanguage}
                  ></CardforButtons>
                </div>
              </Grid>
            )}
            <Grid item>
              <Link
                to={{
                  pathname: `/folio/${eventData.event.extendedProps.bookingId}`,
                  search: `&hotelId=${hotel.hotelId}`,
                }}
                target="_blank"
              >
                <CardforButtons
                  heading={t("View folio")}
                  variant="custom-button"
                  selectedLanguage={selectedLanguage}
                ></CardforButtons>
              </Link>
            </Grid>
            {isNightAuditOn &&
            selectedReservationData?.booking_status === "CHECKED_OUT" &&
            subUser.toLowerCase() === "subuser" ? (
              <></>
            ) : (
              hotelType !== "ISHA" &&
              reservationData?.balance_due > 0 && (
                <Grid item>
                  <div
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <CardforButtons
                      heading={t("Settle dues")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>

                  <Popper
                    style={{ zIndex: 3 }}
                    open={menuOpen}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: (placement = "bottom-end"),
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={() => {
                              setMenuOpen(false);
                            }}
                          >
                            <MenuList
                              id="composition-menu"
                              aria-labelledby="composition-button"
                            >
                              <div
                                className="settleDuesActions"
                                style={{
                                  width: "13rem",
                                  paddingLeft: "10px",
                                }}
                              >
                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.CashPayment
                                ).read === "YES" && (
                                  <div
                                    className="item"
                                    onClick={() => handleOpenCashPayment()}
                                  >
                                    {t("Cash payment")}
                                  </div>
                                )}
                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.OfflineCardPayment
                                ).read === "YES" && (
                                  <div
                                    className="item"
                                    onClick={() => handleOpenOfflineCardPay()}
                                  >
                                    {t("Offline card payment")}
                                  </div>
                                )}
                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.OfflineChequePayment
                                ).read === "YES" && (
                                  <div
                                    className="item"
                                    onClick={() => handleOpenOfflineCheckPay()}
                                  >
                                    {t("Offline cheque payment")}
                                  </div>
                                )}
                                {hotelType !== "ISHA" && currency === "INR" && (
                                  <div
                                    className="item"
                                    onClick={() => handleOpenUpiPay()}
                                  >
                                    {t("UPI Payment")}
                                  </div>
                                )}

                                {hotelType !== "ISHA" &&
                                  JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.OtherPaymentModes
                                  ).read === "YES" && (
                                    <div
                                      className="item"
                                      onClick={() => handleOpenOtherModePay()}
                                    >
                                      {t("Other payment modes")}
                                    </div>
                                  )}

                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.OtherPaymentModes
                                ).read === "YES" && (
                                  <div
                                    className="item"
                                    onClick={() =>
                                      handleOpenBankTransferModePay()
                                    }
                                  >
                                    {t("Bank transfer")}
                                  </div>
                                )}
                                {hotelType !== "ISHA" &&
                                  JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.CashDeposit
                                  ).read === "YES" && (
                                    <div
                                      className="item"
                                      onClick={() => {
                                        setMenuOpen(!menuOpen);
                                        handleOpenCashDeposit();
                                      }}
                                    >
                                      {t("Cash deposit")}
                                    </div>
                                  )}
                                {hotelType !== "ISHA" && (
                                  <div
                                    className="item"
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      handleOpenSendPaymentLink();
                                    }}
                                  >
                                    {t("Send payment link")}
                                  </div>
                                )}
                              </div>
                              {/* <MenuItem
                            onClick={() => {
                              setMenuOpen(!menuOpen);
                              setMenuValue("upgrade");
                            }}
                            key="upgrade"
                          >
                            Move and upgrade resrvation
                          </MenuItem> */}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              )
            )}
            {selectedReservationData?.booking_status !== "CONFIRMED" && (
              <Grid item>
                <div onClick={handleOpenResCard}>
                  <CardforButtons
                    heading={t("Print Reg. Card")}
                    variant="custom-button"
                    selectedLanguage={selectedLanguage}
                  ></CardforButtons>
                </div>
              </Grid>
            )}
            {hotelType !== "ISHA" &&
              reservationData?.is_enquiry === "false" &&
              eventData.event.extendedProps.status === "CONFIRMED" &&
              String(whiteLabelled) === "DISABLED" &&
              JSON.parse(
                props?.data?.accessControl?.user_feature_map
                  ?.MagicLinkCommunication
              ).read === "YES" && (
                <Grid item>
                  <div onClick={handleOpenResCard}>
                    <CardforButtons
                      heading={t("Print Reg. Card")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}
            {hotelType !== "ISHA" &&
              reservationData?.is_enquiry === "false" &&
              eventData.event.extendedProps.status === "CONFIRMED" &&
              String(whiteLabelled) === "DISABLED" &&
              JSON.parse(
                props?.data?.accessControl?.user_feature_map
                  ?.MagicLinkCommunication
              ).read === "YES" && (
                <Grid item>
                  <div onClick={handleOpenMagicLinkModal}>
                    <CardforButtons
                      heading={t("Send Magic Link")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {eventData.event.extendedProps.status === "ADMIN_CONFIRMED" &&
              JSON.parse(props?.data?.accessControl?.user_feature_map?.CheckOut)
                .read === "YES" &&
              JSON.parse(
                props?.data?.accessControl?.user_feature_map
                  ?.GuestCheckInCheckOut
              ).read !== "YES" && (
                <Grid item>
                  {Number(reservationData.balance_due) > 0 ? (
                    <div onClick={() => openFunc("checkout")}>
                      <Link
                        to={{
                          pathname: `folio/${eventData.event.extendedProps.bookingId}`,
                          search: `&hotelId=${hotel.hotelId}`,
                        }}
                      >
                        <CardforButtons
                          heading={t("Checkout")}
                          variant="custom-button"
                          selectedLanguage={selectedLanguage}
                        ></CardforButtons>
                      </Link>
                    </div>
                  ) : (
                    <div onClick={() => openFunc("checkout")}>
                      <CardforButtons
                        heading={t("Checkout")}
                        variant="custom-button"
                        selectedLanguage={selectedLanguage}
                      ></CardforButtons>
                    </div>
                  )}
                </Grid>
              )}

            {selectedReservationData?.booking_status !== "CHECKED_OUT" && (
              <Grid item>
                <div
                  onClick={() => {
                    setMenuValue("hold-booking");
                  }}
                >
                  <CardforButtons
                    heading={t("Hold booking")}
                    variant="custom-button"
                    selectedLanguage={selectedLanguage}
                  ></CardforButtons>
                </div>
              </Grid>
            )}
            {selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              selectedReservationData?.booking_status !== "ADMIN_CONFIRMED" &&
              JSON.parse(hotel?.accessControl?.user_feature_map?.CancelBooking)
                .read === "YES" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("cancel-booking");
                    }}
                  >
                    <CardforButtons
                      heading={t("Cancel booking")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}
            {selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              eventData.event.extendedProps.status !== "ADMIN_CONFIRMED" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("no-show");
                    }}
                  >
                    <CardforButtons
                      heading={t("Set to no show")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}
            {selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              JSON.parse(
                hotel?.accessControl?.user_feature_map?.LockAndUnlockBooking
              ).read === "YES" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("lock-booking");
                    }}
                  >
                    <CardforButtons
                      heading={
                        eventData.event.extendedProps.locked_status !== "LOCKED"
                          ? `${t("Lock booking")}`
                          : `${t("Unlock booking")}`
                      }
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {hotelType === "ISHA" &&
              selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              JSON.parse(hotel?.accessControl?.user_feature_map?.UnassignRoom)
                .read === "YES" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("unassign-room");
                    }}
                  >
                    <CardforButtons
                      heading={t("Unassign room")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}
            {hotelType !== "ISHA" &&
              selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              selectedReservationData?.booking_status !== "ADMIN_CONFIRMED" &&
              JSON.parse(hotel?.accessControl?.user_feature_map?.UnassignRoom)
                .read === "YES" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("unassign-room");
                    }}
                  >
                    <CardforButtons
                      heading={t("Unassign room")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              JSON.parse(hotel?.accessControl?.user_feature_map?.ModifyCheckIn)
                .read === "YES" &&
              selectedReservationData?.booking_status !== "ADMIN_CONFIRMED" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("extend-checkin");
                    }}
                  >
                    <CardforButtons
                      heading={t("Modify checkin")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              JSON.parse(hotel?.accessControl?.user_feature_map?.ModifyCheckOut)
                .read === "YES" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("extend-checkout");
                    }}
                  >
                    <CardforButtons
                      heading={t("Modify checkout")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {/* {showMore && (
            <> */}
            {selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              selectedReservationData?.booking_status !== "ADMIN_CONFIRMED" &&
              JSON.parse(
                hotel?.accessControl?.user_feature_map?.EditReservation
              ).read === "YES" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("move");
                    }}
                  >
                    <CardforButtons
                      heading={t("Edit reservation")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {((subUser.toLowerCase() === "subuser" &&
              hotelemail !== "reservation@maharashtratourism.gov.in") ||
              subUser.toLowerCase() === "admin") &&
              selectedReservationData?.booking_status !== "CHECKED_OUT" &&
              JSON.parse(hotel?.accessControl?.user_feature_map?.SplitRoom)
                .read === "YES" &&
              hotel.slotType !== "PURE_HOURLY" &&
              parseInt(invoiceData?.invoice?.num_nights) > 1 && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("split-room");
                    }}
                  >
                    <CardforButtons
                      heading={t("Split Room")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {((subUser.toLowerCase() === "subuser" &&
              hotelemail !== "reservation@maharashtratourism.gov.in") ||
              subUser.toLowerCase() === "admin") &&
              selectedReservationData?.booking_status !== "CHECKED_OUT" && (
                <Grid item>
                  <div
                    onClick={() => {
                      setMenuValue("move-room");
                    }}
                  >
                    <CardforButtons
                      heading={t("Move Room")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}
            {reservationData.booking_status === "ADMIN_CONFIRMED" &&
              JSON.parse(
                hotel?.accessControl?.user_feature_map?.RollbackBooking
              ).read === "YES" && (
                <Grid item>
                  <div
                    onClick={() => {
                      handleOpenRollbackBookings();
                    }}
                  >
                    <CardforButtons
                      heading={t("Rollback booking")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {reservationData.booking_status === "CANCELLED" &&
              JSON.parse(
                hotel?.accessControl?.user_feature_map?.RollbackBooking
              ).read === "YES" && (
                <Grid item>
                  <div
                    onClick={() => {
                      handleOpenRollbackBookings();
                    }}
                  >
                    <CardforButtons
                      heading={t("Rollback booking")}
                      variant="custom-button"
                      selectedLanguage={selectedLanguage}
                    ></CardforButtons>
                  </div>
                </Grid>
              )}

            {selectedReservationData?.booking_status === "CHECKED_OUT" && (
              <Grid item>
                <div
                  onClick={() => {
                    handleOpenReleaseInventoryModal();
                  }}
                >
                  <CardforButtons
                    heading={t("Release inventory")}
                    variant="custom-button"
                    selectedLanguage={selectedLanguage}
                  ></CardforButtons>
                </div>
              </Grid>
            )}
          </Grid>
        ) : (
          <MockLoaderTable />
        )}
        {openModal && (
          <UnblockView
            eventData={eventData}
            closeFunc={closeBackDrop}
            modalClose={closeModal}
            reload={reload}
            type={eventData.event.extendedProps.status}
            roomInformation={reservationData}
            bookingInfo={invoiceData}
            selectedLanguage={selectedLanguage}
          ></UnblockView>
        )}
      </Grid>
      <div className="d-flex flex-column">
        <div className="mb-5 pb-5 mt-4">
          <Modal show={show} onHide={handleClose} style={{ zIndex: "3000" }}>
            <Modal.Header closeButton>
              <Modal.Title>{t("Create a booking note")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              <TextField
                required
                id="outlined-required"
                label={t("Booking notes")}
                placeholder={t("Enter a note")}
                className="w-100"
                onChange={(e) => setbookingNoteData(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="custom-button" onClick={createBookingNote}>
                {t("Save")}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* //Tag modal */}
          <Modal
            show={showTagModal}
            onHide={handleCloseTagModal}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Booking tags")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="staffTableWrapper">
                <Stack
                  direction={"row"}
                  className="position-relative w-100 d-flex justify-content-start"
                  // spacing={1}
                  style={{
                    flexWrap: "wrap",
                  }}
                >
                  {allHotelTags.map((item) => (
                    <Stack className="mr-2 mb-2">
                      <Button
                        key={item.roomId}
                        variant={
                          allBookingTags?.includes(item.tagName) &&
                          "custom-button"
                        }
                        style={{
                          height: "37.5px",
                          border: allBookingTags?.includes(item.tagName)
                            ? "none"
                            : "2px solid black",
                          color: allBookingTags?.includes(item.tagName)
                            ? "white"
                            : "black",
                          width: "fit-content",
                          padding: "2px",
                          textTransform: "capitalize",
                        }}
                        onClick={() => handleSelectedBookingTags(item.tagName)}
                      >
                        {" "}
                        {item.tagName}{" "}
                      </Button>
                    </Stack>
                  ))}
                  <TextField
                    label={t("Remark")}
                    className="input-lg w-100 mt-3"
                    placeholder="Enter remark  here"
                    onChange={(e) => setremark(e.target.value)}
                    value={remark}
                    name="schedule_name"
                    id="schedule_name"
                  />
                </Stack>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseTagModal}>
                {t("Close")}
              </Button>
              <Button variant="custom-button" onClick={updateBookingTags}>
                {t("Save")}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={openMagicLinkModal}
            onHide={handleCloseMagicLinkModal}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <div className="d-flex flex-column">
                <Modal.Title>{t("Send magic link")}</Modal.Title>
                <p className="text-muted mb-0">
                  {t(
                    "Magic Link connects your guests to your property in real time. They can view the itinerary, perform self check-ins, room upgrades, stay extensions, and add-on purchases."
                  )}
                </p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div
                className="fieldWrapper"
                style={{
                  position: "relative",
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: 500,
                  backgroundColor: "#C8E5F3",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "black",
                }}
              >
                {envType.toLowerCase() === "prod"
                  ? `https://selfservice.stayflexi.com/booking/${reservationData.booking_id}`
                  : `https://beta.selfservice.stayflexi.com/booking/${reservationData.booking_id}`}
              </div>
              <br></br>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Email")}
                  name="email"
                  helperText={`${t(
                    "By default customer email has been populated. Override if necessary."
                  )}`}
                  value={magicLinkMail}
                  onChange={(e) => setMagicLinkMail(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <br></br>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Phone number")}
                  name="phone"
                  helperText={`${t(
                    "By default customer contact number has been populated. Override if necessary."
                  )}`}
                  value={magicLinkPhone}
                  onChange={(e) => {
                    /^[\+0-9]*$/.test(e.target.value) &&
                      setMagicLinkPhone(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {magicLinkLoading === false ? (
                <Button
                  className="submit w-auto"
                  variant="custom-button"
                  onClick={() => handleSendMagicLink()}
                >
                  {t("Send Magic Link")}
                </Button>
              ) : (
                <Button className="submit">
                  <CircularProgress size="15px" />
                </Button>
              )}
            </Modal.Footer>
          </Modal>{" "}
          {/* Modal to provide the type of res card that needs to be printed */}
          <Modal
            show={openResCard}
            onHide={handleCloseResCard}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Print Registration card")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="folioConfirmationWrapper">
                <div
                  className="folioContent"
                  style={{
                    borderBottom: "1px solid #DDDDDD",
                    paddingBottom: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <Stack direction="row" spacing={3}>
                    <Checkbox
                      checked={manualResCard}
                      onChange={handleChangeManualResCard}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <div>
                      {t(
                        "I will fill up the details manually into the Registration card!"
                      )}
                    </div>
                  </Stack>
                </div>
                <Stack direction="row">
                  <div className="updateBtn">
                    {manualResCard === true ? (
                      <div className="item" style={{ paddingTop: "10px" }}>
                        <PDFDownloadLink
                          document={
                            <Document title={reservationData?.booking_id}>
                              <Page size="A4" style={styles.page} wrap>
                                {customFolioConfig?.reservation_card_version ===
                                "rescardV2" ? (
                                  <PrintEmptyVehicleResCard
                                    invoice={invoiceData?.invoice}
                                    symbol={currency}
                                    hotelData={invoiceData?.hotel}
                                    customDetails={customFolioConfig}
                                    hotelLogo={hotelLogo}
                                    summaryData={summaryData}
                                    hotelType={hotelType}
                                  />
                                ) : (
                                  <PrintEmptyResCard
                                    invoice={invoiceData?.invoice}
                                    symbol={currency}
                                    hotelData={invoiceData?.hotel}
                                    customDetails={customFolioConfig}
                                    hotelLogo={hotelLogo}
                                    summaryData={summaryData}
                                    hotelType={hotelType}
                                  />
                                )}
                              </Page>
                            </Document>
                          }
                          fileName={`${reservationData?.booking_id}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <p style={{ fontSize: "0.8rem" }}>
                                {t("Loading document...")}
                              </p>
                            ) : (
                              <a
                                href={url}
                                style={{ fontSize: "0.8rem" }}
                                rel={"noreferrer"}
                                target="_blank"
                              >
                                {window.screen.width > 416 && (
                                  <Button
                                    variant="custom-button"
                                    onClick={() =>
                                      setTimeout(() => {
                                        handleCloseResCard();
                                      }, 1500)
                                    }
                                  >
                                    {t("Print Registration card")}
                                  </Button>
                                )}
                              </a>
                            )
                          }
                        </PDFDownloadLink>
                      </div>
                    ) : (
                      <div className="item" style={{ paddingTop: "10px" }}>
                        {invoiceData && (
                          <PDFDownloadLink
                            document={
                              <Document title={reservationData.booking_id}>
                                <Page
                                  size="A4"
                                  style={[
                                    styles.page,
                                    {
                                      paddingTop:
                                        customFolioConfig?.reservation_card_version ===
                                          "rescardV2" && 10,
                                      paddingBottom:
                                        customFolioConfig?.reservation_card_version ===
                                          "rescardV2" && 10,
                                    },
                                  ]}
                                  wrap
                                >
                                  {customFolioConfig?.reservation_card_version ===
                                  "rescardV2" ? (
                                    <PrintVehicleResCard
                                      allCompanyDetails={allCompanyDetails}
                                      invoice={invoiceData?.invoice}
                                      symbol={getCurrencySymbol(currency)}
                                      hotelData={invoiceData?.hotel}
                                      customDetails={customFolioConfig}
                                      hotelLogo={hotelLogo}
                                      summaryData={summaryData}
                                      hotelType={hotelType}
                                      ctaDetails={
                                        defaultCtadropdown?.filter(
                                          (cta) =>
                                            cta?.cta_name ===
                                            invoiceData?.invoice?.booking_source
                                        )[0]
                                      }
                                      selectedLanguage={props.selectedLanguage}
                                    />
                                  ) : customFolioConfig?.reservation_card_version ===
                                    "rescardV1" ? (
                                    <PrintResCard
                                      allCompanyDetails={allCompanyDetails}
                                      invoice={invoiceData?.invoice}
                                      symbol={currency}
                                      hotelData={invoiceData?.hotel}
                                      customDetails={customFolioConfig}
                                      hotelLogo={hotelLogo}
                                      summaryData={summaryData}
                                      hotelType={hotelType}
                                      ctaDetails={
                                        defaultCtadropdown?.filter(
                                          (cta) =>
                                            cta?.cta_name ===
                                            invoiceData?.invoice?.booking_source
                                        )[0]
                                      }
                                      selectedLanguage={props.selectedLanguage}
                                    />
                                  ) : null}
                                </Page>
                              </Document>
                            }
                            fileName={`${reservationData?.booking_id}.pdf`}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                <p style={{ fontSize: "0.8rem" }}>
                                  {t("Loading document...")}
                                </p>
                              ) : (
                                <a
                                  href={url}
                                  style={{ fontSize: "0.8rem" }}
                                  rel={"noreferrer"}
                                  target="_blank"
                                >
                                  {window.screen.width > 416 && (
                                    <Button
                                      variant="custom-button"
                                      onClick={() =>
                                        setTimeout(() => {
                                          handleCloseResCard();
                                        }, 1500)
                                      }
                                    >
                                      {t("Print Registration card")}
                                    </Button>
                                  )}
                                </a>
                              )
                            }
                          </PDFDownloadLink>
                        )}
                      </div>
                    )}
                  </div>
                </Stack>
              </div>
            </Modal.Body>
          </Modal>
          {/* settle dues --- record payment from cash payment */}
          <Mike
            open={openCashPayment}
            onClose={handleCloseCashPayment}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="settleDuesModal"
              style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
            >
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  <div className="settleDuesHeading">
                    {t("Enter cash amount")}
                    <div className="roomIdHeader"></div>
                  </div>
                  <div
                    className="close"
                    onClick={() => handleCloseCashPayment()}
                  >
                    X
                  </div>
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Cash amount")}
                      name="amount"
                      value={amount}
                      className="mb-4"
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setAmount(e.target.value)
                          : setAmount(amount)
                      }
                      disabled={
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map
                            ?.EditBookingPrice
                        ).read !== "YES"
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Return to customer calculator")}
                      name="amount"
                      className="mb-4"
                      value={
                        Number(amount) >=
                        Number(reservationData.balance_due).toFixed(2)
                          ? (
                              Number(amount) -
                              Number(reservationData.balance_due).toFixed(2)
                            ).toFixed(2)
                          : `${t("Less amount")}`
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                      InputProps={{ inputProps: { min: 0 } }}
                      disabled
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        style={{ zIndex: "10000" }}
                        value={offlineCashDate}
                        onChange={(newValue) => {
                          setOfflineCashDate(newValue);
                        }}
                        name="offline_card_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Amount description")}
                      className="mb-4 mt-3"
                      name="service_amount_desc"
                      value={serviceAmountDesc}
                      onChange={(e) => setServiceAmountDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="cancelBtn"
                    onClick={() => handleCloseCashPayment()}
                  >
                    {t("Cancel")}
                  </button>
                  {cashPayLoading === false ? (
                    <Button
                      className="submit"
                      onClick={() => handleCashPayment()}
                      variant="custom-button"
                      disabled={
                        serviceAmountDesc !== ""
                          ? amount > 0
                            ? false
                            : true
                          : true
                      }
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          </Mike>
          {/* settle dues --- record payment from other sources */}
          <Mike
            open={openOtherModePay}
            onClose={handleCloseOtherModePay}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="settleDuesModal">
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  <div className="settleDuesHeading">{t("Enter amount")}</div>
                  <div
                    className="close"
                    onClick={() => handleCloseOtherModePay()}
                  >
                    X
                  </div>
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      required
                      id="outlined-name"
                      label={t("Deposit amount")}
                      name="deposit_amount"
                      className="mb-3"
                      value={otherModeAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setOtherModeAmt(e.target.value)
                          : setOtherModeAmt(otherModeAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Service date")}
                        value={otherModeDate}
                        onChange={(newValue) => {
                          setOtherModeDate(newValue);
                        }}
                        name="deposit_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Description")}
                      name="other_mode_desc"
                      className="mt-3 mb-3"
                      value={otherModeDesc}
                      onChange={(e) => setOtherModeDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                  <div
                    className="settleDuesSubmitBtns"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className="cancelBtn"
                      onClick={() => handleCloseOtherModePay()}
                    >
                      {t("Cancel")}
                    </Button>
                    {otherModePayLoading === false ? (
                      <Button
                        className="submit"
                        variant="custom-button"
                        onClick={() => handleOtherModePayment()}
                        disabled={otherModeAmt > 0 ? false : true}
                      >
                        {t("Submit")}
                      </Button>
                    ) : (
                      <Button className="submit">
                        <CircularProgress size="15px" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </Mike>
          {/* settle dues --- Bank Transfer */}
          <Mike
            open={openBankTransferModePay}
            onClose={handleCloseBankTransferModePay}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="settleDuesModal"
              style={{
                boxShadow: "0px 0px 25px 10px #0000001a",
              }}
            >
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  <div className="settleDuesHeading">
                    {t("Enter bank transfer amount")}
                  </div>
                  <div
                    className="close"
                    onClick={() => handleCloseBankTransferModePay()}
                  >
                    X
                  </div>
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Amount")}
                      name="amount"
                      value={bankTransferModeAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setBankTransferModeAmt(e.target.value)
                          : setBankTransferModeAmt(bankTransferModeAmt)
                      }
                      sx={{ width: "100%", marginBottom: "1rem" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={bankTransferModeDate}
                        onChange={(newValue) => {
                          setBankTransferModeDate(newValue);
                        }}
                        name="upi_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Remarks")}
                      name="description"
                      value={bankTransferModeDesc}
                      onChange={(e) => setBankTransferModeDesc(e.target.value)}
                      sx={{
                        width: "100%",
                        marginBottom: "1rem",
                        marginTop: "1rem",
                      }}
                    />
                  </div>
                </div>
                <div className="settleDuesSubmitBtns">
                  {bankTransferModePayLoading === false ? (
                    <Button
                      className="submit"
                      variant="custom-button"
                      onClick={() => handleBankTransferPayment()}
                      disabled={bankTransferModeAmt > 0 ? false : true}
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          </Mike>
          {/* settle dues --- cash deposit modal */}
          <Mike
            open={openCashDeposit}
            onClose={handleCloseCashDeposit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="settleDuesModal">
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  <div className="settleDuesHeading">
                    {t("Enter deposit amount")}
                  </div>
                  <div
                    className="close"
                    onClick={() => handleCloseCashDeposit()}
                  >
                    X
                  </div>
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      required
                      id="outlined-name"
                      label={t("Cash deposit amount*")}
                      name="deposit_amount"
                      className="mb-3"
                      value={depositAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setDepositAmt(e.target.value)
                          : setDepositAmt(depositAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Service date")}
                        value={depositDate}
                        onChange={(newValue) => {
                          setDepositDate(newValue);
                        }}
                        name="deposit_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Description*")}
                      className="mb-3 mt-3"
                      name="folio_item_name"
                      value={depositName}
                      onChange={(e) => setDepositName(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                  <div
                    className="settleDuesSubmitBtns"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className="cancelBtn"
                      onClick={() => handleCloseCashDeposit()}
                    >
                      {t("Cancel")}
                    </Button>
                    {depositLoading === false ? (
                      <Button
                        className="submit"
                        variant="custom-button"
                        onClick={() => handleDeposit()}
                        disabled={depositAmt > 0 ? false : true}
                      >
                        {t("Submit")}
                      </Button>
                    ) : (
                      <Button className="submit">
                        <CircularProgress size="15px" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </Mike>
          {/* settle dues --- record UPI payment modal */}
          <Mike
            open={openUpi}
            onClose={handleCloseUpiPay}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="settleDuesModal"
              style={{
                boxShadow: "0px 0px 25px 10px #0000001a",
              }}
            >
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  <div className="settleDuesHeading">
                    {t("Enter UPI amount")}
                  </div>
                  <div className="close" onClick={() => handleCloseUpiPay()}>
                    X
                  </div>
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("UPI amount")}
                      name="amount"
                      className="mb-3"
                      value={upiAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setUpiAmt(e.target.value)
                          : setUpiAmt(upiAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={upiDate}
                        onChange={(newValue) => {
                          setUpiDate(newValue);
                        }}
                        name="upi_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Description")}
                      name="description"
                      className="mb-3 mt-3"
                      value={upiDesc}
                      onChange={(e) => setUpiDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="cancelBtn"
                    onClick={() => handleCloseOfflineCheckPay()}
                  >
                    {t("Cancel")}
                  </Button>
                  {upiPayLoading === false ? (
                    <Button
                      className="submit"
                      variant="custom-button"
                      onClick={() => handleUpiPayment()}
                      disabled={upiAmt > 0 ? false : true}
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          </Mike>
          {/* settle dues --- paymentLink */}
          <Mike
            open={openSendPaymentLink}
            onClose={handleCloseSendPaymentLink}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="settleDuesModal"
              style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
            >
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  <div className="settleDuesHeading">
                    {t("Send Payment Link")}
                  </div>
                  <div
                    className="close"
                    onClick={() => handleCloseSendPaymentLink()}
                  >
                    X
                  </div>
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Email")}
                      name="email"
                      className="mb-3"
                      helperText={`${"By default customer email has been populated. Override if necessary."}`}
                      value={paymentLinkEmail}
                      onChange={(e) => setPaymentLinkEmail(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Mobile number")}
                      name="mobile"
                      className="mb-3"
                      helperText={`${t(
                        "By default customer mobile has been populated. Override if necessary."
                      )}`}
                      value={paymentLinkMobile}
                      onChange={(e) => setPaymentLinkMobile(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Amount")}
                      className="mb-3"
                      helperText={`${t(
                        "By default total due amount has been populated. Override if necessary."
                      )}`}
                      name="service_amount_desc"
                      value={paymentLinkAmt}
                      onChange={(e) => setPaymentLinkAmt(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button className="cancelBtn">{t("Cancel")}</Button>
                  {paymentLinkLoading === false && hotelType !== "ISHA" ? (
                    <Button
                      variant="custom-button"
                      className="submit w-auto"
                      onClick={() => handlePaymentLink()}
                    >
                      {t("Send Payment Link")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          </Mike>
          {/* settle dues --- offline cheque payment modal */}
          <Mike
            open={openOfflineCheckPay}
            onClose={handleCloseOfflineCheckPay}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="settleDuesModal"
              style={{
                boxShadow: "0px 0px 25px 10px #0000001a",
              }}
            >
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  <div className="settleDuesHeading">
                    {t("Enter cheque amount")}
                  </div>
                  <div
                    className="close"
                    onClick={() => handleCloseOfflineCheckPay()}
                  >
                    X
                  </div>
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Cheque amount")}
                      name="amount"
                      className="mb-3"
                      value={offlineCheckAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setOfflineCheckAmt(e.target.value)
                          : setOfflineCheckAmt(offlineCheckAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={offlineCheckDate}
                        onChange={(newValue) => {
                          setOfflineCheckDate(newValue);
                        }}
                        name="offline_check_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Description")}
                      className="mb-3 mt-3"
                      name="description"
                      value={chequeDesc}
                      onChange={(e) => setChequeDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="cancelBtn"
                    onClick={() => handleCloseOfflineCheckPay()}
                  >
                    {t("Cancel")}
                  </Button>
                  {offlineCheckPayLoading === false ? (
                    <Button
                      className="submit"
                      variant="custom-button"
                      onClick={() => handleOfflineCheckPayment()}
                      disabled={offlineCheckAmt > 0 ? false : true}
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          </Mike>
          <Modal
            show={openRollbackBooking}
            onHide={handleCloseRollbackBooking}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Rollback booking")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p> {t("Do you want to rollback this booking?")}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => handleCloseRollbackBooking()}
              >
                {t("Close")}
              </Button>
              <Button
                variant="custom-button"
                onClick={() =>
                  handleRollbackBooking(reservationData.booking_id)
                }
                disabled={rollbackLoader}
              >
                {rollbackLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Rollback Booking")}`
                )}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* settle dues --- offline card payment modal */}
          <Mike
            open={openOfflineCardPay}
            onClose={handleCloseOfflineCardPay}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="settleDuesModal"
              style={{
                boxShadow: "0px 0px 25px 10px #0000001a",
              }}
            >
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  <div className="settleDuesHeading">
                    {t("Enter card amount")}
                  </div>
                  <div
                    className="close"
                    onClick={() => handleCloseOfflineCardPay()}
                  >
                    X
                  </div>
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Card amount")}
                      name="amount"
                      className="mb-3"
                      value={offlineCardAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setOfflineCardAmt(e.target.value)
                          : setOfflineCardAmt(offlineCardAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                      disabled={
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map
                            ?.EditBookingPrice
                        ).read !== "YES"
                      }
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={offlineCardDate}
                        onChange={(newValue) => {
                          setOfflineCardDate(newValue);
                        }}
                        name="offline_card_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label="Amount description"
                      className="mb-3 mt-3"
                      name="service_amount_desc"
                      value={serviceAmountDesc}
                      onChange={(e) => setOfflineCardDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>

                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="cancelBtn"
                    onClick={() => handleCloseOfflineCardPay()}
                  >
                    {t("Cancel")}
                  </button>
                  {offlineCardPayLoading === false ? (
                    <>
                      {JSON.parse(
                        hotel?.accessControl?.user_feature_map?.EnquiryDashboard
                      ).read === "YES" ? (
                        <Button
                          className="submit"
                          variant="custom-button"
                          onClick={() => handleOfflineCardPayment()}
                          disabled={offlineCardAmt > 0 ? false : true}
                        >
                          {t("Submit")}
                        </Button>
                      ) : (
                        <Button
                          className="submit"
                          onClick={() => handleOfflineCardPayment()}
                          variant="custom-button"
                          disabled={offlineCardAmt > 0 ? false : true}
                        >
                          {t("Submit")}
                        </Button>
                      )}
                    </>
                  ) : (
                    <button className="submit">
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </div>
            </Box>
          </Mike>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(CustomerInformation);
