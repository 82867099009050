import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api/index";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MockInvoiceTable from "./MockInvoiceTable";
import { Stack, Typography, Tooltip, Divider } from "@mui/material";
import ViewDetailedItems from "../Shops/Shop/ViewOrders/ViewDetailedItems";
import SettlePayment from "../Shops/Shop/PlaceOrders/Components/SettlePayment";
import PrintBillModal from "../Shops/Shop/PlaceOrders/Utils/PrintBillModal";
import { connect } from "react-redux";
import axios from "axios";
import * as HiIcons from "react-icons/hi";
import moment from "moment";
import { roundOffValues } from "../../Utils/Utils";
import DisplayAllPosOrders from "./POS/DisplayAllPosOrders";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  maxHeight: 750,
  height: "fit-content",
  overflowY: "scroll",
};

const roomFolioTableStyle = {
  nonDesc: {
    position: "relative",
    width: "10%",
    wordBreak: "break-all",
  },
  desc: {
    position: "relative",
    width: "40%",
    wordWrap: "break-word",
  },
};

function InvoiceSummaryTable({
  addServiceBtn,
  invoiceData,
  bookingId,
  hotelId,
  updateInvoice,
  updateSummaryData,
  updateAdvInvoice,
  currency,
  loggedUserEmail,
  handleTrueWrapperMockLoader,
  handleFalseWrapperMockLoader,
  handleTrueSummaryMockLoader,
  handleFalseSummaryMockLoader,
  hotelLogo,
  data,
  hotelType,
  ishaEreceiptGenerated,
  summaryData,
  reservationId,
  isNightAuditOn,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  // Getting the hotel currency
  const hotelCurrency = localStorage.getItem("hotelCurrency");
  const [subUser, setsubUser] = useState(data?.accessControl?.ADMINRIGHTS);

  const [hotelConvertedCurrency, sethotelConvertedCurrency] = useState(
    localStorage.getItem("hotelCurrency")
  );
  const [hotelConvertedCurrencySymbol, sethotelConvertedCurrencySymbol] =
    useState(localStorage.getItem("symbol"));

  useEffect(() => {
    if (
      sessionStorage.getItem("convertedHotelCurrency") != null &&
      sessionStorage.getItem("convertedHotelCurrency") !== undefined
    ) {
      sethotelConvertedCurrency(
        sessionStorage.getItem("convertedHotelCurrency")
      );
      sethotelConvertedCurrencySymbol(
        sessionStorage.getItem("convertedHotelCurrencySymbol")
      );
      if (
        hotelCurrency !== "" &&
        hotelCurrency !== undefined &&
        hotelCurrency !== null &&
        sessionStorage.getItem("convertedHotelCurrency") !== "" &&
        sessionStorage.getItem("convertedHotelCurrency") !== undefined &&
        sessionStorage.getItem("convertedHotelCurrency") !== null
      ) {
        getConvertedvalue();
      }
    }
  }, [
    sessionStorage.getItem("convertedHotelCurrency"),
    sessionStorage.getItem("defaultRate"),
  ]);

  // Get the order info based on the order id
  const [orderId, setOrderId] = useState(null);
  // const [shopId, setShopId] = useState(null);

  // getting order
  const [orderInfo, setOrderInfo] = useState(null);
  const handleOrderInfo = () => {
    orderId &&
      handleSpringDataRequest(
        `core/api/v1/orders/get-order-by-id?orderId=${orderId}`
      )
        .then((res) => setOrderInfo(res))
        .catch((err) =>
          toast.error(
            `${t("Error in getting the order info for the order")} ${orderId}`
          )
        );
  };

  useEffect(() => {
    handleOrderInfo();
  }, [orderId]);

  // getting all the order as well as the order details
  const [orderDetails, setOrderDetails] = useState([]);

  const handleOrderDetails = () =>
    orderId &&
    handleSpringDataRequest(
      `core/api/v1/order-details/get-od-for-order?orderId=${orderId}&shopId=${orderInfo?.shop_id}`
    )
      .then((res) => setOrderDetails(res))
      .catch((err) =>
        toast.error(
          `${t("Error in fetching the details for order id")} ${orderId}.`
        )
      );

  useEffect(() => {
    if (orderInfo) {
      handleOrderDetails();
    }
  }, [orderId, orderInfo]);

  // Modal to display detailed item lists
  const [openDetailedItemList, setOpenDetailedItemList] = useState(false);
  const handleOpenDetailedItemList = () => {
    return setOpenDetailedItemList(true);
  };
  const handleCloseDetailedItemList = () => {
    return setOpenDetailedItemList(false);
  };

  // Get the subtotal bill --- initializing state
  const getSubtotalBill = () => {
    var subTotal = 0;
    const data = orderDetails?.map(
      (item) => (subTotal += Number(item.product_price))
    );
    return parseFloat(subTotal).toFixed(2);
  };

  // // Get total discount in the bill
  const getTotalDiscount = () => {
    if (orderInfo) {
      return orderInfo.total_order_discount
        ? orderInfo.total_order_discount
        : 0;
    }
  };

  // Initializing the total tax state
  const getTotalTax = () => {
    if (orderInfo) {
      return orderInfo?.tax_percentage;
    }
  };

  // Get the total bill
  const getTotalBill = () => {
    if (orderInfo) {
      return orderInfo?.order_amount;
    }
  };

  useEffect(() => {
    getTotalDiscount();
    getTotalBill();
    getTotalTax();
  }, [orderId]);

  // Displaying the mock invoice table loader while performing any operation into the table
  const [mockTable, setMockTable] = useState(false);
  const mockTableTrue = () => setMockTable(true);
  const mockTableFalse = () => setMockTable(false);

  // getting the services data for the invoice

  const { SearchBar } = Search;

  // let products = invoiceData?.invoice.items;
  const [products, setproducts] = useState([]);

  // Creating the products payload
  // 1. Regular items
  // 2. Late checkout items
  useEffect(() => {
    // Creating the cumulative POS item
    const data = invoiceData?.items?.items?.filter(
      (item) => !["null", ""].includes(item?.pos_order_id)
    );
    let cumulativePos = {};
    let posItems = [];
    if (data.length > 0) {
      cumulativePos = data.reduce((acc, curr) => {
        acc["service_amount"] = !acc.hasOwnProperty("service_amount")
          ? curr["service_amount"]
          : acc["service_amount"] + curr["service_amount"];
        acc["service_tax"] = !acc.hasOwnProperty("service_tax")
          ? curr["service_tax"]
          : acc["service_tax"] + curr["service_tax"];
        acc["service_total"] = !acc.hasOwnProperty("service_total")
          ? curr["service_total"]
          : acc["service_total"] + curr["service_total"];
        acc["pos_service_charge"] = !acc.hasOwnProperty("pos_service_charge")
          ? curr["pos_service_charge"]
          : acc["pos_service_charge"] + curr["pos_service_charge"];

        return acc;
      }, {});
      cumulativePos["service_id"] = "-1";
      cumulativePos["pos_order_id"] = "";
      cumulativePos["service_desc"] = "Cumulative POS orders";
      cumulativePos["service_amount_type"] = "-";
      cumulativePos["service_date"] = "-";
      posItems = [cumulativePos];
    }

    if (invoiceData.items.self_service_items) {
      setproducts([
        ...invoiceData?.items?.items?.filter((item) =>
          ["null", ""].includes(item?.pos_order_id)
        ),
        ...invoiceData?.items?.self_service_items,
        ...posItems,
      ]);
    } else {
      setproducts(
        ...invoiceData?.items?.items?.filter((item) =>
          ["null", ""].includes(item?.pos_order_id)
        ),
        ...posItems
      );
    }
  }, [invoiceData]);

  const [convertedAmount, setconvertedAmount] = useState(0);

  // List of the service Id(s) which aren't supposed to be used for checkbox selection
  // -1 is for POS orders
  // -2 is for Booking fees
  const [nonSelectableArr, setNonSelectableArr] = useState(["0", "-1", "-2"]);

  // Get the non selectable indices
  const getNonSelectableIndices = () => {
    if (products.length > 0) {
      products.forEach(
        (item, index) =>
          (item?.service_desc?.includes("Promo discount") ||
            item?.service_desc?.includes("Cumulative POS orders")) &&
          !nonSelectableArr.includes(item?.service_id) &&
          setNonSelectableArr([...nonSelectableArr, String(item?.service_id)])
      );
    }
  };

  useEffect(() => {
    getNonSelectableIndices();
  }, [products]);

  useEffect(() => {
    // Creating the cumulative POS item
    const data = invoiceData?.items?.items?.filter(
      (item) => !["null", ""].includes(item?.pos_order_id)
    );
    let cumulativePos = {};
    let posItems = [];
    if (data.length > 0) {
      cumulativePos = data.reduce((acc, curr) => {
        acc["service_amount"] = !acc.hasOwnProperty("service_amount")
          ? curr["service_amount"]
          : acc["service_amount"] + curr["service_amount"];
        acc["service_tax"] = !acc.hasOwnProperty("service_tax")
          ? curr["service_tax"]
          : acc["service_tax"] + curr["service_tax"];
        acc["service_total"] = !acc.hasOwnProperty("service_total")
          ? curr["service_total"]
          : acc["service_total"] + curr["service_total"];
        acc["pos_service_charge"] = !acc.hasOwnProperty("pos_service_charge")
          ? curr["pos_service_charge"]
          : acc["pos_service_charge"] + curr["pos_service_charge"];

        return acc;
      }, {});
      cumulativePos["service_id"] = "-1";
      cumulativePos["pos_order_id"] = "";
      cumulativePos["service_desc"] = "Cumulative POS orders";
      cumulativePos["service_amount_type"] = "-";
      cumulativePos["service_date"] = "-";
      posItems = [cumulativePos];
    }
    let totalBookingFee = [];
    if (Number(invoiceData?.invoice?.total_fees) > 0) {
      let bookingFee = {};
      bookingFee["service_id"] = "-2";
      bookingFee["service_amount"] = Number(invoiceData?.invoice?.total_fees);
      bookingFee["service_tax"] = "-";
      bookingFee["service_total"] = Number(invoiceData?.invoice?.total_fees);
      bookingFee["service_amount_type"] = "DEBIT";
      bookingFee["service_date"] = moment(
        invoiceData?.invoice?.checkin,
        "YYYY-MM-DDTHH:mm"
      ).format("MMM DD YYYY");
      bookingFee["pos_order_id"] = "";
      bookingFee["service_desc"] = "Booking fees";
      bookingFee["line_item_type"] = "BOOKING_FEES";
      bookingFee["line_item_id"] = "-2";
      totalBookingFee.push(bookingFee);
    }

    if (invoiceData.items.self_service_items) {
      sessionStorage.setItem(
        "items",
        JSON.stringify([
          ...invoiceData?.items?.items?.filter((item) =>
            ["null", ""].includes(item?.pos_order_id)
          ),
          ...invoiceData?.items?.self_service_items,
          ...posItems,
          ...totalBookingFee,
        ])
      );
    } else {
      sessionStorage.setItem(
        "items",
        JSON.stringify([
          ...invoiceData?.items?.items?.filter((item) =>
            ["null", ""].includes(item?.pos_order_id)
          ),
          ...posItems,
          ...totalBookingFee,
        ])
      );
    }

    // if (data.length > 0) {
    //   sessionStorage.setItem(
    //     "items",
    //     JSON.stringify([
    //       JSON.parse(sessionStorage.getItem("items")),
    //       ...[cumulativePos],
    //     ])
    //   );
    // }
  }, []);

  const getConvertedvalue = async () => {
    if (
      sessionStorage.getItem("defaultRate") === 0 &&
      sessionStorage.getItem("defaultRate") === undefined &&
      sessionStorage.getItem("defaultRate") === null
    ) {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}`
      );
      setconvertedAmount(data.data);
    } else {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}&amount=${sessionStorage.getItem("defaultRate")}`
      );
      setconvertedAmount(data.data);
    }
  };
  useEffect(() => {
    if (
      hotelCurrency !== null &&
      hotelCurrency !== undefined &&
      hotelConvertedCurrency !== null &&
      hotelConvertedCurrency !== undefined &&
      hotelConvertedCurrency !== ""
    ) {
      if (hotelCurrency !== hotelConvertedCurrency) {
        const data = JSON.parse(sessionStorage.getItem("items"))?.map((res) => {
          let e = res;
          e["service_amount"] = e["service_amount"] * convertedAmount;
          e["service_tax"] = e["service_tax"] * convertedAmount;
          e["service_total"] = e["service_total"] * convertedAmount;
          return e;
        });
        setproducts(data);
      } else {
        setproducts(JSON.parse(sessionStorage.getItem("items")));
      }
    }
  }, [convertedAmount, hotelConvertedCurrency]);

  // loader while editing the service amonut
  const [editPriceLoading, setEditPriceLoading] = useState(false);

  // editing the service amount
  const handleEditServiceAmt = (originalAmt, newAmt) => {
    // handleTrueSummaryMockLoader()
    const disc = products?.filter(
      (item) => item?.service_amount_desc === "Promo discount"
    );
    if (disc?.length > 0) {
      if (Math.abs(disc[0]?.service_total) > newAmt) {
        toast.error(
          `${t("The new amount can't be lower than the applied discount")}`
        );
        updateInvoice();
        return;
      }
    }
    mockTableTrue();
    setEditPriceLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/editInvoiceBookingAmount/`,
        {
          hotelId: hotelId,
          booking_id: bookingId,
          originalAmt: originalAmt,
          newAmt: newAmt,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => {
        updateSummaryData();
        toast.success(`${t("Successfully updated the changes")}`);
      })
      .then((res) => updateInvoice())
      .then((res) => mockTableFalse())
      .then((res) => setEditPriceLoading(false))
      // .then((res) => handleFalseSummaryMockLoader())
      .catch((err) => toast.error(`${t("Failed to edit the amount")}`))
      .then((res) => setEditPriceLoading(false))
      .then((res) => mockTableFalse());
  };

  // editing the booking amount pretax
  const handleEditBookingAmountPreTax = (originalAmt, newAmt) => {
    // handleTrueSummaryMockLoader()
    const disc = products?.filter(
      (item) => item?.service_amount_desc === "Promo discount"
    );
    if (disc?.length > 0) {
      if (Math.abs(disc[0]?.service_amount) > newAmt) {
        toast.error(
          `${t("The new amount can't be lower than the applied discount")}`
        );
        updateInvoice();
        return;
      }
    }
    mockTableTrue();
    setEditPriceLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/editInvoiceBookingAmountPretax/`,
        {
          hotelId: hotelId,
          bookingId: bookingId,
          updatePrice: originalAmt,
          newAmt: newAmt,
          systemUserEmail: loggedUserEmail,
        }
      )
      .then((res) => updateSummaryData())
      .then((res) => updateInvoice())
      .then((res) => mockTableFalse())
      .then((res) => setEditPriceLoading(false))
      // .then((res) => handleFalseSummaryMockLoader())
      .catch((err) => toast.error(`${t("Failed to edit the amount")}`))
      .then((res) => setEditPriceLoading(false))
      .then((res) => mockTableFalse());
  };

  // Render text decoration based on the type of the service added to the POS
  const renderTextDecoration = (text, row) => {
    // if (
    //   data?.accessControl?.accessUser?.accessmap?.user_access_map?.allow_total_room_price_edit_main_folio.toLowerCase() ===
    //     "yes" &&
    //   data?.accessControl?.accessUser?.accessmap?.EditBookingPrice
    // ) {
    //   if (
    //     text === "Cumulative POS orders" ||
    //     text.includes("Promo discount") ||
    //     row?.service_id === "-2"
    //   ) {
    //     return "none";
    //   } else {
    //     return "underline";
    //   }
    // } else {
    //   return "none";
    // }
    const allowRoomPriceEdit =
      JSON.parse(
        data?.accessControl?.user_feature_map?.AllowTotalRoomPriceEditMainFolio
      ).read === "YES";

    const canEditBookingPrice =
      allowRoomPriceEdit &&
      JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
        .read === "YES";

    const isPosOrPromoOrMinusTwo =
      text === "Cumulative POS orders" ||
      text.includes("Promo discount") ||
      row?.service_id === "-2";

    return canEditBookingPrice && !isPosOrPromoOrMinusTwo
      ? "underline"
      : "none";
  };

  const renderTextWeight = (text, row) => {
    // if (
    //   data?.accessControl?.accessUser?.accessmap?.user_access_map?.allow_total_room_price_edit_main_folio.toLowerCase() ===
    //     "yes" &&
    //   data?.accessControl?.accessUser?.accessmap?.EditBookingPrice
    // ) {
    //   if (
    //     text === "Cumulative POS orders" ||
    //     text.includes("Promo discount") ||
    //     row?.service_id === "-2"
    //   ) {
    //     return 400;
    //   } else {
    //     return 500;
    //   }
    // } else {
    //   return 400;
    // }
    const allowRoomPriceEdit =
      JSON.parse(
        data?.accessControl?.user_feature_map?.AllowTotalRoomPriceEditMainFolio
      ).read === "YES";

    const canEditBookingPrice =
      allowRoomPriceEdit &&
      JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
        .read === "YES";
    const isPosOrPromoOrMinusTwo =
      text === "Cumulative POS orders" ||
      text.includes("Promo discount") ||
      row?.service_id === "-2";

    return canEditBookingPrice && !isPosOrPromoOrMinusTwo ? 500 : 400;
  };

  /* ORDER DETAILS */
  // Getting all the order details

  // order formatter
  const handleOrderFormatter = (cell, row) => {
    if (row?.service_desc.includes("POS ORDER")) {
      // let splitVal = row?.service_desc.split(",")[0];
      // splitVal = splitVal.split("-")[1].trim();
      // setOrderId(splitVal);
      // let subStrIdx = row?.service_desc.indexOf("Order");
      // let subStr = row?.service_desc.slice(subStrIdx, row?.service_desc.length);
      // subStr = subStr.split("-")[1].replace(".", "").trim();
      setOrderId(row?.pos_order_id);
    }
  };

  // const renderTextDecorationForServiceDesc = (text) => {
  //   if (text?.includes("POS ORDER")) {
  //     return "underline";
  //   } else {
  //     return "none";
  //   }
  // };

  /* SETTLE ORDER */
  const [openSettlePaymentModal, setOpenSettlePaymentModal] = useState(false);
  const handleOpenSettlePaymentModal = () => setOpenSettlePaymentModal(true);
  const handleCloseSettlePaymentModal = () => setOpenSettlePaymentModal(false);
  // Get payment details for the order
  const [orderPayments, setOrderPayments] = useState(null);
  /* API */
  const handlePaymentForOrder = () => {
    orderInfo &&
      handleSpringDataRequest(
        `core/api/v1/order-payment/get-all?orderId=${orderInfo?.id}`
      )
        .then((res) => setOrderPayments(res))
        .catch((err) =>
          toast.error(
            `${t(
              "Error in fetching payment status for the order. Please try again later."
            )}`
          )
        );
  };

  useEffect(() => {
    handlePaymentForOrder();
  }, [orderInfo]);

  // get total amount paid
  const handleGetTotalAmtPaid = () => {
    let totalPaidAmt = 0.0;
    if (orderPayments) {
      orderPayments.forEach((item) => {
        totalPaidAmt += Number(item?.settled_amount);
      });
    }
    return Number(totalPaidAmt.toFixed(2));
  };

  // Get the table name from the table id
  /* API CALL to get all the tables */

  const [tables, setTables] = useState([]);

  const handleAllTables = () => {
    orderInfo &&
      handleSpringDataRequest(
        `core/api/v1/order-table/get-all?shopId=${orderInfo?.shop_id}`
      )
        .then((res) =>
          setTables(
            res.filter(
              (table) =>
                !["DELETED", "INACTIVE"].includes(table?.table_active_status)
            )
          )
        )
        .catch((err) =>
          toast.error(
            `${t("Error in getting tables ! Please try again later.")}`
          )
        );
  };
  useEffect(() => {
    handleAllTables();
  }, [orderInfo]);

  const getTableName = (id) => {
    if (String(id).length > 0) {
      const specificTable = tables.filter(
        (table, index) => String(table?.id) === String(id)
      );
      return specificTable[0]?.table_name;
    } else {
      return "";
    }
  };

  /* OUTLET DETAILS */
  // Get all the outlet details based on the id
  const [outletDetails, setOutletDetails] = useState(null);
  // get the specific shop details
  const getShopDetails = () => {
    orderInfo &&
      handleSpringDataRequest(
        `core/api/v1/shop/category/get-all?email=${localStorage.getItem(
          "email"
        )}`
      ).then((res) =>
        res.filter(
          (detail) =>
            String(detail.id) === String(orderInfo?.shop_id) &&
            setOutletDetails(detail)
        )
      );
  };

  useEffect(() => {
    getShopDetails();
  }, [orderInfo]);

  // Tax percentage formatter
  const taxPercentageFormatter = (cell, row) => {
    // let feeBreakdown =
    // Object.entries(summaryData?.fee_breakdown).length > 0
    //   ? Number(Object.entries(summaryData?.fee_breakdown)[0][1].toFixed(2))
    //   : 0;
    // let feeBreakdown = 0.0;
    // if (row?.pos_order_id.length === 0) {
    //   if (Object.entries(summaryData?.fee_breakdown)?.length > 0) {
    //     Object.values(summaryData?.fee_breakdown).map((fee) => {
    //       // console.log("key : ", key, val);
    //       feeBreakdown = feeBreakdown + fee;
    //     });
    //   }
    // }

    let taxPercent = 0;
    if (row?.service_amount != 0) {
      taxPercent = Number(row?.service_tax) / Number(row?.service_amount);
      taxPercent = taxPercent * 100;
    } else {
      taxPercent = 0;
    }
    return roundOffValues(Number(taxPercent));
  };

  // Modal to give the detailed desc for room items
  const [openRoomFolio, setOpenRoomFolio] = useState(false);
  const handleOpenRoomFolio = () => setOpenRoomFolio(true);
  const handleCloseRoomFolio = () => setOpenRoomFolio(false);

  // Getting the room folio details
  const [roomFolioInfo, setRoomFolioInfo] = useState(null);
  function getRoomFolioInfo() {
    handleSpringDataRequest(
      `core/api/v1/folio/get-stay-invoice?bookingId=${bookingId}`
    )
      .then((res) => setRoomFolioInfo(res))
      .catch((err) => toast.error(err));
  }

  useEffect(() => {
    hotelType === "ISHA" && getRoomFolioInfo();
  }, [invoiceData]);

  // Get the aggregated values for the room folio items
  function getAggValues(attr) {
    if (roomFolioInfo) {
      let total = 0;
      roomFolioInfo.forEach((item) => (total += item[attr]));
      return Number(total.toFixed(0));
    } else {
      return 0.0;
    }
  }

  // function ifTotalEditable(cell, row) {
  //   const isEreceiptGenerated =
  //     ishaEreceiptGenerated && hotelCurrency === hotelConvertedCurrency;
  //   const canEditBookingPrice =
  //     data?.accessControl?.accessUser?.accessmap?.EditBookingPrice &&
  //     data?.accessControl?.accessUser?.accessmap?.user_access_map?.allow_total_room_price_edit_main_folio?.toLowerCase() ===
  //       "yes";
  //   const isPosOrderOrPromoDiscount =
  //     row?.service_desc.includes("POS ORDER") ||
  //     row?.service_desc.includes("Promo discount");
  //   const isServiceIdMinusTwo = row?.service_id === "-2";

  //   const shouldAllow = isEreceiptGenerated
  //     ? false
  //     : canEditBookingPrice ||
  //       !isPosOrderOrPromoDiscount ||
  //       !isServiceIdMinusTwo;

  //   return shouldAllow;
  // }

  const columns = [
    {
      dataField: "service_id",
      text: `${t("Id")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      hidden: true,
      editable: false,
    },
    {
      dataField: "pos_order_id",
      text: `${t("POS Id")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      hidden: true,
      editable: false,
    },
    {
      dataField: "service_date",
      text: `${t("Date")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "125px",
      },
      editable: false,
    },
    {
      dataField: "service_desc",
      text: `${t("Description")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "170px",
      },
      style: (cell, row) => {
        return {
          cursor: "pointer",
          fontWeight: cell === "Cumulative POS orders" ? 700 : 400,
          textDecoration:
            cell === "Cumulative POS orders" ? "underline" : "none",
        };
      },
      editable: false,
      formatter: (cell, row) => (
        <Stack
          direction="row"
          spacing={1}
          style={{ display: "flex", flexWrap: "wrap", wordBreak: "break-all" }}
        >
          <Typography
            onClick={() => {
              handleOrderFormatter(cell, row);
              row?.service_desc.includes("Cumulative POS orders") &&
                handleOpenAllPosOrders();
            }}
          >
            {row?.service_desc}
          </Typography>
          {row?.service_id === "0" && hotelType === "ISHA" && (
            <Tooltip title={t("Get room folio breakup")}>
              <Typography onClick={handleOpenRoomFolio}>
                <HiIcons.HiInformationCircle color="c4c4c4" size="17px" />
              </Typography>
            </Tooltip>
          )}
        </Stack>
      ),
    },
    {
      dataField: "service_amount_type",
      text: `${t("Type")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "100px",
      },
      formatter: (cell, row) => (
        <Typography style={{ wordBreak: "break-all" }}>
          {row?.service_amount_type}
        </Typography>
      ),
      editable: false,
    },
    {
      dataField: "service_amount",
      text: `${t("Sub-total")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "125px",
      },
      formatter: (cell) => {
        return Math.abs(parseFloat(cell)).toFixed(2);
      },
      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: renderTextWeight(row?.service_desc, row),
          textDecoration: renderTextDecoration(row?.service_desc, row),
        };
      },
      editable: (cell, row) =>
        isNightAuditOn &&
        invoiceData.invoice.status === "CHECKED_OUT" &&
        subUser.toLowerCase() === "subuser"
          ? false
          : hotelCurrency === hotelConvertedCurrency &&
            JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
              .read === "YES"
          ? JSON.parse(
              data?.accessControl?.user_feature_map
                ?.AllowTotalRoomPriceEditMainFolio
            ).read === "YES"
            ? row?.service_desc.includes("Cumulative POS orders") ||
              row?.service_id === "-2"
              ? false
              : row?.service_desc.includes("Promo discount")
              ? false
              : true
            : false
          : false,
      hidden: hotelType === "ISHA" && true,
    },
    {
      isDummyField: true,
      text: `${t("Cumulative tax %")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) =>
        ["-1", "-2"].includes(row.service_id)
          ? "-"
          : taxPercentageFormatter(cell, row),
      editable: false,
      // hidden: hotelType === "ISHA" && true,
      hidden:
        JSON.parse(data?.accessControl?.user_feature_map?.CumulativeTax)
          .read === "N/A" ||
        JSON.parse(data?.accessControl?.user_feature_map?.CumulativeTax)
          .read === "NO",
    },
    {
      dataField: "service_tax",
      text: `${t("Tax")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => {
        if (row?.service_id === "-2") {
          return "-";
        }
        return parseFloat(cell).toFixed(2);
      },
      editable: false,
      hidden: hotelType === "ISHA" && true,
    },
    {
      dataField: "pos_service_charge",
      text: `${t("Service charge")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => {
        if (
          ["null", ""].includes(row?.pos_order_id) &&
          row?.service_desc !== "Cumulative POS orders"
        ) {
          return "-";
        }
        return parseFloat(cell).toFixed(2);
      },
      editable: false,
      hidden:
        (hotelType === "ISHA" ||
          invoiceData?.items?.items?.filter(
            (item) => !["null", ""].includes(item?.pos_order_id)
          ).length === 0) &&
        true,
    },
    {
      dataField: "service_total",
      text: `${t("Total")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "125px",
      },
      formatter: (cell) => {
        return Math.abs(parseFloat(cell)).toFixed(2);
      },
      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: renderTextWeight(row?.service_desc, row),
          textDecoration: renderTextDecoration(row?.service_desc, row),
        };
      },
      editable: (cell, row) =>
        isNightAuditOn &&
        invoiceData.invoice.status === "CHECKED_OUT" &&
        subUser.toLowerCase() === "subuser"
          ? false
          : ishaEreceiptGenerated === false
          ? hotelCurrency === hotelConvertedCurrency &&
            JSON.parse(data?.accessControl?.user_feature_map?.EditBookingPrice)
              .read === "YES"
            ? JSON.parse(
                data?.accessControl?.user_feature_map
                  ?.AllowTotalRoomPriceEditMainFolio
              ).read === "YES"
              ? row?.service_desc.includes("Cumulative POS orders") ||
                row?.service_desc.includes("Promo discount") ||
                row?.service_id === "-2"
                ? false
                : // : row?.service_desc.includes("Promo discount")
                  // ? false
                  // : row?.service_id === "-2"
                  // ? false
                  true
              : false
            : false
          : false,
    },
    // {
    //   dataField: "loader",
    //   isDummyField: true,
    //   formatter: (cell, row, rowIndex) => {
    //     if (editPriceLoading) {
    //       return <CircularProgress size="15px" color="secondary" />;
    //     } else if (addonServiceLoading) {
    //       return <CircularProgress width="15px" color="secondary" />;
    //     } else {
    //       return "";
    //     }
    //   },
    //   editable: false,
    // },
  ];

  // getting the selected row data

  // selected rows ids
  const [selectedRows, setSelectedRows] = useState([]);
  // selected rows desc list
  const [selectedRowsDesc, setSelectedRowsDesc] = useState([]);
  // selected rows amt list
  const [selectedRowsAmt, setSelectedRowsAmt] = useState([]);
  // selected rows tax list
  const [selectedRowsTax, setSelectedRowsTax] = useState([]);

  // row selection for operations
  // selecting the rows
  const handleOnSelect = (row, isSelect) => {
    if (
      (isSelect && row.service_id === "0") ||
      row.service_amount_type.toLowerCase() === "promocode"
    ) {
      return false;
    } else if (isSelect) {
      setSelectedRows((selectedRows) => [...selectedRows, row.service_id]);
      setSelectedRowsDesc((selectedRows) => [
        ...selectedRows,
        row.service_desc,
      ]);
      setSelectedRowsAmt((selectedRows) => [
        ...selectedRows,
        parseInt(row.service_amount),
      ]);
      setSelectedRowsTax((selectedRows) => [
        ...selectedRows,
        parseInt(row.service_tax),
      ]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x !== row.service_id));
      setSelectedRowsDesc(selectedRows.filter((x) => x !== row.service_desc));
      setSelectedRowsAmt(
        selectedRows.filter((x) => x !== parseInt(row.service_amount))
      );
      setSelectedRowsTax(
        selectedRows.filter((x) => x !== parseInt(row.service_tax))
      );
    }
  };

  // selecting all rows at once
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows
      .filter(
        (row) =>
          row.service_id !== "0" ||
          row.service_amount_type.toLowerCase() !== "promocode"
      )
      .map((r) => r.service_id);
    const desc = rows
      .filter(
        (row) =>
          row.service_id !== "0" ||
          row.service_amount_type.toLowerCase() !== "promocode"
      )
      .map((r) => r.service_desc);
    const amount = rows
      .filter(
        (row) =>
          row.service_id !== "0" ||
          row.service_amount_type.toLowerCase() !== "promocode"
      )
      .map((r) => parseInt(r.service_amount));
    const tax = rows
      .filter(
        (row) =>
          row.service_id !== "0" ||
          row.service_amount_type.toLowerCase() !== "promocode"
      )
      .map((r) => parseInt(r.service_tax));

    if (isSelect) {
      setSelectedRows(ids);
      setSelectedRowsDesc(desc);
      setSelectedRowsAmt(amount);
      setSelectedRowsTax(tax);
    } else {
      setSelectedRows([]);
      setSelectedRowsDesc([]);
      setSelectedRowsAmt([]);
      setSelectedRowsTax([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    nonSelectable: nonSelectableArr,
  };

  // editing the add on service amount
  const [addonServiceLoading, setAddonServiceLoading] = useState(false);
  const handleEditAddonAmt = (serviceId, newAmt) => {
    mockTableTrue();
    setAddonServiceLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/editInvoiceAddonAmount/", {
      hotelId: hotelId,
      booking_id: bookingId,
      service_id: serviceId,
      amount_type: "TOTAL",
      newAmt: newAmt,
      logged_user_email: loggedUserEmail,
    })
      .then((res) => updateSummaryData())
      .then((res) => setAddonServiceLoading(false))
      .then((res) =>
        setTimeout(() => {
          updateInvoice();
        }, 200)
      )
      .then((res) => mockTableFalse())
      .catch((err) =>
        toast.error(
          `${t("Sorry! Something wrong happened. Please try again later!")}`
        )
      )
      .catch((err) => setAddonServiceLoading(false))
      .then((res) => mockTableFalse());
  };

  // removing the services
  // confirmation modal to remove the service
  const [openRemoveService, setOpenRemoveService] = useState(false);
  const handleOpenRemoveService = () => setOpenRemoveService(true);
  const handleCloseRemoveService = () => setOpenRemoveService(false);

  // remove service loader
  const [removeServiceLoader, setRemoveServiceLoader] = useState(false);

  // handling removing the service --- api
  function handleRemoveService() {
    setRemoveServiceLoader(true);
    mockTableTrue();
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/removeServicesFromInvoice/",
      {
        hotelId: hotelId,
        booking_id: bookingId,
        service_item_ids: selectedRows,
        service_desc_list: selectedRowsDesc,
        service_amount_list: selectedRowsAmt,
        service_tax_list: selectedRowsTax,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) => updateSummaryData())
      .then((res) =>
        setTimeout(() => {
          updateInvoice();
        }, 200)
      )
      .then((res) => mockTableFalse())
      .then((res) => toast.success(`${"Successfully removed the items."}`))
      .then((res) => setRemoveServiceLoader(false))
      .then((res) => handleCloseRemoveService())
      .then((res) => setSelectedRows([]))
      .then((res) => setSelectedRowsDesc([]))
      .then((res) => setSelectedRowsAmt([]))
      .then((res) => setSelectedRowsTax([]))
      .catch((error) =>
        toast.error(`${"Ooops! Cannot remove the selected item."}`)
      )
      .then((res) => setRemoveServiceLoader(false))
      .then((res) => handleCloseRemoveService())
      .then((res) => setSelectedRows([]))
      .then((res) => setSelectedRowsDesc([]))
      .then((res) => setSelectedRowsAmt([]))
      .then((res) => setSelectedRowsTax([]))
      .then((res) => mockTableFalse());
  }

  // Render a modal in order to display all the pos orders
  const [openAllPosOrders, setOpenAllPosOrders] = useState(false);
  // Handle open all the POS orders
  function handleOpenAllPosOrders() {
    return setOpenAllPosOrders(true);
  }
  // Handle close all the POS orders
  function handleCloseAllPosOrders() {
    return setOpenAllPosOrders(false);
  }

  return (
    <>
      {!mockTable ? (
        <div className="invoiceSummTableWrapper">
          <ToolkitProvider
            keyField="service_id"
            data={products}
            columns={columns}
            // search
          >
            {(props) => (
              <>
                <div className="filtersWrapper">
                  <div className="searchWrapper"></div>
                  {selectedRows.length > 0 &&
                    hotelCurrency === hotelConvertedCurrency && (
                      <button
                        className="removeServiceBtn"
                        onClick={() => handleOpenRemoveService()}
                      >
                        {t("Remove")}
                      </button>
                    )}
                </div>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    selectRow={selectRow}
                    pagination={paginationFactory()}
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      afterSaveCell: (
                        oldValue,
                        newValue,
                        row,
                        column,
                        rowIndex
                      ) => {
                        if (column.dataField === "service_amount") {
                          if (
                            row.service_id === "0" &&
                            Number(oldValue) !== Number(newValue)
                          ) {
                            handleEditBookingAmountPreTax(oldValue, newValue);
                          }
                        } else {
                          if (
                            row.service_id === "0" &&
                            Number(oldValue) !== Number(newValue)
                          ) {
                            handleEditServiceAmt(oldValue, newValue);
                          } else if (
                            row.service_id !== "0" &&
                            Number(oldValue) !== Number(newValue)
                          ) {
                            handleEditAddonAmt(row.service_id, newValue);
                          }
                        }
                      },
                    })}
                    // rowStyle = {rowStyle}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockInvoiceTable />
      )}

      {/* confirmation modal to remove the service items */}
      <Modal
        open={openRemoveService}
        onClose={handleCloseRemoveService}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              {t("Are you sure you want to delete the items ?")}
            </div>
            <div className="folioConfButtons">
              <button
                className="cancel"
                onClick={() => handleCloseRemoveService()}
              >
                {t("Cancel")}
              </button>
              {removeServiceLoader === false ? (
                <button
                  className="approved"
                  onClick={() => handleRemoveService()}
                  style={{ width: "150px" }}
                >
                  {t("Confirm")}
                </button>
              ) : (
                <button className="approved" style={{ width: "150px" }}>
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal to dispaly order item details */}
      <ViewDetailedItems
        openDetailedItemList={openDetailedItemList}
        handleOpenDetailedItemList={handleOpenDetailedItemList}
        handleCloseDetailedItemList={handleCloseDetailedItemList}
        // orderDetailedId={orderId}
        orderDetails={orderDetails}
        // subtotal={getSubtotalBill()}
        // totalTax={getTotalTax()}
        // totalDiscount={getTotalDiscount()}
        // totalAmt={getTotalBill()}
        invoice={true}
        handleOpenSettlePaymentModal={handleOpenSettlePaymentModal}
        // orderStatus={orderInfo && orderInfo.order_status}
        balanceDue={
          orderPayments &&
          orderInfo &&
          orderPayments[orderPayments.length - 1]?.balance_amount
        }
        // balanceDue={
        //   orderPayments &&
        //   orderInfo &&
        //   (orderPayments[orderPayments.length - 1]?.balance_amount <
        //   orderInfo?.order_amount
        //     ? orderPayments[orderPayments.length - 1]?.balance_amount
        //     : orderPayments[orderPayments.length - 1]?.balance_amount +
        //       orderInfo?.round_off_amount)
        // }
        // shopId={orderInfo?.shop_id}
        // shopName={outletDetails?.shop_name}
        // shopAddress={outletDetails?.shop_address}
        // taxIdentificationNo={outletDetails?.tax_identification_number}
        hotelCurrency={hotelCurrency}
        hotelLogo={hotelLogo}
        // customerName={invoiceData?.invoice.customer_name}
        // customerPhone={invoiceData?.invoice.customer_phone}
        // customerEmail={invoiceData?.invoice.customer_email}
        // customerGSTNo={orderInfo && orderInfo?.cust_gst_no}
        // staffId={orderInfo && orderInfo?.order_staff_id}
        handleUpdateBillAfterPrint={handleOrderInfo}
        // invoiceNumber={orderInfo && orderInfo?.invoice_no}
        // orderDate={orderInfo && orderInfo?.order_date}
        orderPunchedInfo={
          orderInfo && orderInfo?.room_id !== -1
            ? orderInfo?.room_id
            : orderInfo?.order_table_id !== -1
            ? orderInfo?.room_id
            : null
        }
        orderPunchedType={
          orderInfo && orderInfo?.room_id !== -1
            ? "Room No.(s)"
            : orderInfo?.order_table_id !== -1
            ? "Table No."
            : null
        }
        // serviceCharges={orderInfo?.service_charge}
        // serviceChargeInfo={{
        //   serviceChargeType: outletDetails?.service_charge_type,
        //   serviceChargeVal: outletDetails?.service_charge_value,
        //   applyServiceChargeByDefault:
        //     orderInfo && orderInfo?.apply_service_charge_bydefault,
        // }}
        // paxDetails={orderInfo && orderInfo?.no_of_persons}
        // roundOffError={orderInfo && orderInfo?.round_off_amount}
        // checkComplimentaryOrder={
        //   orderInfo && orderInfo?.complimentary_order?.toLowerCase() === "no"
        //     ? false
        //     : true
        // }
        // Adding new fields
        order={orderInfo}
      />

      {/* Modal for settle payment */}
      <SettlePayment
        openSettlePaymentModal={openSettlePaymentModal}
        // handleOpenSettlePaymentModal={handleOpenSettlePaymentModal}
        handleCloseSettlePaymentModal={handleCloseSettlePaymentModal}
        // tableId={orderInfo?.order_table_id}
        tableName={getTableName(orderInfo?.order_table_id)}
        // shopId={orderInfo?.shop_id}
        // orderId={orderInfo?.id}
        balanceDue={
          orderPayments &&
          orderInfo &&
          orderPayments[orderPayments.length - 1]?.balance_amount
        }
        // balanceDue={
        //   orderPayments &&
        //   orderInfo &&
        //   (orderPayments[orderPayments.length - 1]?.balance_amount <
        //   orderInfo?.order_amount
        //     ? orderPayments[orderPayments.length - 1]?.balance_amount
        //     : orderPayments[orderPayments.length - 1]?.balance_amount +
        //       orderInfo?.round_off_amount)
        // }
        // checkComplimentaryOrder={
        //   orderInfo && orderInfo?.complimentary_order?.toLowerCase() === "no"
        //     ? false
        //     : true
        // }
        // shopName={outletDetails && outletDetails.shop_name}
        // hotelId={orderInfo?.hotel_id}
        // tax={orderInfo?.tax_percentage}
        paymentId={orderInfo?.id}
        paymentPaid={handleGetTotalAmtPaid()}
        handleGetAllOrders={updateInvoice}
        // customerName={orderInfo?.cust_name}
        // customerEmail={orderInfo?.cust_email}
        // customerPhone={orderInfo?.cust_phone}
        updatePayment={true}
        // bookingId={bookingId}
        loggedUserEmail={loggedUserEmail}
        updateSummaryData={updateSummaryData}
        order={orderInfo}
        selectedLanguage={selectedLanguage}
      />
      {/* Modal to give the detailed breakup for the room items */}
      <Modal
        open={openRoomFolio}
        onClose={handleCloseRoomFolio}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack>
            <Stack
              direction="row"
              className="position-relative d-flex flex w-100 p-5 justify-content-between"
              style={{
                backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
                color: "white",
              }}
            >
              <Typography>
                <strong>{t("Room folio breakup")}</strong>
              </Typography>
              <Typography>
                <strong
                  style={{ fontSize: "15px", cursor: "pointer" }}
                  onClick={handleCloseRoomFolio}
                >
                  X
                </strong>
              </Typography>
            </Stack>
            <Divider />
            <Stack className="position-relative w-100 mt-5 p-5">
              <table
                style={{
                  width: "100%",
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>{t("Date")}</th>
                    <th>{t("Room nights")}</th>
                    <th style={{ width: "30%" }}>{t("Description")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Sub-total")}</th>
                    <th>{t("Tax")}</th>
                    <th>{t("Tax %")}</th>
                    <th>{t("Total")}</th>
                  </tr>
                </thead>

                {roomFolioInfo ? (
                  <>
                    <tbody>
                      {roomFolioInfo?.map((item, index) => (
                        <tr key={index}>
                          <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                          <td>{item.roomNights}</td>
                          <td>{item.desc}</td>
                          <td>{item.type}</td>
                          <td>{Number(item.preTaxAmt.toFixed(0))}</td>
                          <td>{Number(item.taxes.toFixed(0))}</td>
                          <td>{Number(item.taxPercent.toFixed(0))}</td>
                          <td>{Number(item.amount.toFixed(0))}</td>
                        </tr>
                      ))}
                    </tbody>

                    <tfoot>
                      <tr>
                        <th>{t("Total")}</th>
                        <th>{getAggValues("roomNights")}</th>
                        <th> </th>
                        <th> </th>
                        <th>{getAggValues("preTaxAmt")}</th>
                        <th>{getAggValues("taxes")}</th>
                        <th> </th>
                        <th>{getAggValues("amount")}</th>
                      </tr>
                    </tfoot>
                  </>
                ) : (
                  <CircularProgress size="15px" />
                )}
              </table>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      {/* Displaying all the POS orders */}
      <Modal
        open={openAllPosOrders}
        onClose={handleCloseAllPosOrders}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { width: 1200 }]} className="scrollbar">
          <Stack>
            <Stack
              direction="row"
              className="position-relative d-flex flex w-100 p-5 justify-content-between"
              style={{
                backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
                color: "white",
              }}
            >
              <Typography>
                <strong>{t("POS items")}</strong>
              </Typography>
              <Typography>
                <strong
                  style={{ fontSize: "15px", cursor: "pointer" }}
                  onClick={handleCloseAllPosOrders}
                >
                  X
                </strong>
              </Typography>
            </Stack>
            <Divider />
            <DisplayAllPosOrders
              hotelConvertedCurrency={hotelConvertedCurrency}
              hotelCurrency={hotelCurrency}
              currency={currency}
              posItems={invoiceData?.items?.items?.filter(
                (item) => !["null", ""].includes(item?.pos_order_id)
              )}
              handleOrderFormatter={handleOrderFormatter}
              handleOpenDetailedItemList={handleOpenDetailedItemList}
              hotelConvertedCurrencySymbol={hotelConvertedCurrencySymbol}
              taxPercentageFormatter={taxPercentageFormatter}
              bookingId={bookingId}
              updateInvoice={updateInvoice}
              updateSummaryData={updateSummaryData}
              handleCloseAllPosOrders={handleCloseAllPosOrders}
              loggedUserEmail={loggedUserEmail}
              reservationId={reservationId}
              roomId={invoiceData?.invoice?.room_ids_list[0]}
              selectedLanguage={selectedLanguage}
            />
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(InvoiceSummaryTable);
