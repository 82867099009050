// ** React Imports
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import { cloneDeep } from "lodash";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DateRange } from "react-date-range";
import { makeStyles, useTheme } from "@mui/styles";
import moment from "moment";
import { weekdays } from "../constants/InventoryConstants";
import {
  getChannels,
  getRoomInformation,
  setPmsInventory,
  setPricingInventory,
} from "../api/InventoryApi";
import {
  customErrorMessage,
  dateFormat,
} from "../../CalendarPage/constants/Calendar-constants";
import { isNull, isUndefined } from "lodash-es";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useFormik } from "formik";
import * as yup from "yup";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import amplitude from "amplitude-js";
import { ENV } from "../../CalendarPage/constants/AmplitudeConstants";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import "../Views/OtASync.css";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  textDisplay: {
    fontSize: "12px",
    marginRight: 6,
  },
  subText: {
    fontSize: "14px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SetPricing = (props) => {
  const {
    data,
    selectedRoom,
    rooms,
    setLineInvisible,
    setLineVisible,
    refresh,
    componentType,
    closeFunc,
    roomAndPlans,
    roomAndPlanscopy,
    selectedLanguage,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const DropdownOptions = [
    { text: `${t("Set Pricing")}`, val: "set-price" },
    { text: `${t("Set Inventory")}`, val: "set-inventory" },
    { text: `${t("Rate Multiplier")}`, val: "rate-multiplier" },
    { text: `${t("Inventory Capping")}`, val: "inventory-cap" },
    { text: `${t("Set Online")}`, val: "set-online" },
    { text: `${t("Set Offline")}`, val: "set-offline" },
    { text: `${t("Block Rooms")}`, val: "block-room" },
    { text: `${t("Unblock Rooms")}`, val: "unblock-room" },
    { text: `${t("Delete Rooms")}`, val: "delete-room" },
    { text: `${t("Restore Deleted Rooms")}`, val: "restore" },
    { text: `${t("Update Rates from OTA")}`, val: "update-ota" },
    { text: `${t("Set Sold Out")}`, val: "sold-out" },
    { text: `${t("Edit Room Ids")}`, val: "edit-room" },
    { text: `${t("Reassign Room Type")}`, val: "reassign" },
    { text: `${t("Close/Open Rate Plan")}`, val: "Close Rate" },
    { text: `${t("Close/Open Room Type")}`, val: "Close Room" },
    { text: `${t("Close/Open On Arrival")}`, val: "Close On Arrival" },
    { text: `${t("Close/Open On Departure")}`, val: "Close On Departure" },
    { text: `${t("Min Length of Stay")}`, val: "Set Min Los" },
    { text: `${t("Max Length of Stay")}`, val: "Set Max Los" },
    { text: `${t("Min Advanced Booking Offset")}`, val: "Set Cutoff" },
    { text: `${t("Max Advanced Booking Offset")}`, val: "Set Max Adv Offset" },
  ];

  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(
        new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
    },
  ]);

  //Amplitude check on Bulk Operations page
  const amplitudeEventProperties = {
    hotel_id: data?.hotelId,
    environment: ENV,
    email: data?.accessControl?.email,
    role: data?.accessControl?.ADMINRIGHTS,
  };

  const [selectDays, setSelectDays] = useState(weekdays);

  const newweekdays = [
    { id: "Sunday", value: `${t("Sunday")}` },
    { id: "Monday", value: `${t("Monday")}` },
    { id: "Tuesday", value: `${t("Tuesday")}` },
    { id: "Wednesday", value: `${t("Wednesday")}` },
    { id: "Thursday", value: `${t("Thursday")}` },
    { id: "Friday", value: `${t("Friday")}` },
    { id: "Saturday", value: `${t("Saturday")}` },
  ];
  const getValue = (val) => {
    const res = newweekdays.filter((item) => item.id === val)[0];
    return res.value;
  };

  const [pricesObj, setPricesObj] = useState([{}]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [completeRoomInfo, setCompleteRoomInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [ratePlans, setRatePlans] = useState({});
  const [selectedRatePlan, setSelectedRatePlan] = useState({});
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    channels.length > 0 && selectedChannel.length === channels.length;

  const [bulkEditPayload, setbulkEditPayload] = useState([
    {
      roomTypeName: "",
      fromDate: currentSelection[0]?.startDate,
      toDate: currentSelection[0]?.endDate,
      roomCount: "",
      daysIncluded: selectDays,
    },
  ]);

  const [bulkEditPayloadForPricing, setbulkEditPayloadForPricing] = useState([
    {
      roomType: "",
      roomTypeId: "",
      ratePlanId: "",
      startDate: currentSelection[0]?.startDate,
      endDate: currentSelection[0]?.endDate,
      broadcastRates: true,
      occupancyPricing: {},
      sources: [],
      daysIncluded: selectDays,
      changeBaseRate: true,
    },
  ]);

  const addInventory = () => {
    let payload = {
      roomTypeName: "",
      fromDate: currentSelection[0]?.startDate,
      toDate: currentSelection[0]?.endDate,
      roomCount: "",
      daysIncluded: selectDays,
    };
    let data = [...bulkEditPayload];
    data.push(payload);
    setbulkEditPayload(data);
  };

  const addPricing = () => {
    let payload = {
      roomType: "",
      roomTypeId: "",
      ratePlanId: "",
      startDate: currentSelection[0]?.startDate,
      endDate: currentSelection[0]?.endDate,
      broadcastRates: true,
      occupancyPricing: {},
      sources: [],
      daysIncluded: selectDays,
      changeBaseRate: true,
    };
    let data = [...bulkEditPayloadForPricing];
    data.push(payload);
    let tempArray = [...pricesObj];
    tempArray.push({});
    setPricesObj(tempArray);
    setbulkEditPayloadForPricing(data);
  };

  const updatePricing = async (idx, e, pricesObj) => {
    const { name, value } = e.target;
    let data = bulkEditPayloadForPricing[idx];
    if (pricesObj !== undefined && pricesObj !== null) {
      data[`occupancyPricing`] = pricesObj;
    } else {
      data[name] = value;
    }

    data[`daysIncluded`] = selectDays;
    data[`changeBaseRate`] = true;
    data[`broadcastRates`] = true;
    data[`startDate`] = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    data[`endDate`] = moment(currentDateSelection[0].endDate).format(
      dateFormat
    );

    const updatedBulkEdit = bulkEditPayloadForPricing.map((bulkEdit, index) => {
      if (index === idx) {
        return data;
      } else {
        return bulkEdit;
      }
    });
    setbulkEditPayloadForPricing(updatedBulkEdit);
  };

  function updateInventory(idx, e) {
    const { name, value } = e.target;
    let data = bulkEditPayload[idx];

    data[name] = value;

    data[`daysIncluded`] = selectDays;
    data[`fromDate`] = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    data[`toDate`] = moment(currentDateSelection[0].endDate).format(dateFormat);

    const updatedBulkEdit = bulkEditPayload.map((bulkEdit, index) => {
      if (index === idx) {
        return data;
      } else {
        return bulkEdit;
      }
    });
    setbulkEditPayload(updatedBulkEdit);
  }

  function removeInventory(idx) {
    let data = [...bulkEditPayload];
    data.splice(idx, 1);
    setbulkEditPayload(data);
  }

  function removePricing(idx) {
    let data = [...bulkEditPayloadForPricing];
    data.splice(idx, 1);
    setbulkEditPayloadForPricing(data);
  }

  const validationSchema =
    componentType === "set-price"
      ? yup.object({
          currentDateSelection: yup
            .array()
            .required(
              `${t("Select a range and click on apply for atleast one range")}`
            ),
          // priceFields: yup
          //   .array()
          //   .required("Fill in atleast one field")
          //   .of(yup.number().min(0, "Enter prices greater than 0")),
        })
      : yup.object({
          currentDateSelection: yup
            .array()
            .required(
              `${t("Select a range and click on apply for atleast one range")}`
            ),
          // roomCount: yup
          //   .number()
          //   .min(0, "Room count cannot be less than 0")
          //   .required("Room count is required"),
        });

  const formik = useFormik({
    initialValues:
      componentType === "set-price"
        ? { currentDateSelection: [] }
        : { currentDateSelection: [] },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (componentType === "set-price") {
        setShowConfirm(true);
      } else {
        setApiLoading(true);
        // setShowConfirm(true);
        const arr = [];
        let tempData = cloneDeep(bulkEditPayload);

        const guest = tempData?.map((data) => {
          let temp = data;
          temp["roomTypeName"] = rooms[data?.roomTypeName];
          return temp;
        });

        for (let i = 0; i < currentSelection?.length; i++) {
          guest.map((item) => {
            let tempItem = { ...item };
            tempItem[`fromDate`] = currentSelection[i].startDate;
            tempItem[`toDate`] = currentSelection[i].endDate;
            arr.push(tempItem);
          });
        }

        axios
          .put(
            `${process.env.REACT_APP_BG_API_URL}core/api/v1/inventory/bulk-set-pms-inventory-count`,
            arr
          )
          .then((res) => {
            toast.success(`${t("Successfully updated the inventory")}`);
            setbulkEditPayload([
              {
                roomTypeName: "",
                fromDate: currentSelection[0]?.startDate,
                toDate: currentSelection[0]?.endDate,
                roomCount: "",
                daysIncluded: selectDays,
              },
            ]);
            setApiLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setApiLoading(false);
          });
      }
    },
  });
  useEffect(() => {
    setInitailData();
  }, [selectedRoom]);

  const setInitailData = async () => {
    setLineVisible();
    setLoading(true);
    try {
      switch (componentType) {
        case "set-price":
          await setRoomData({ roomTypeId: selectedRoom });
          break;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLineInvisible();
      setLoading(false);
    }
  };

  const getDisplayData = () => {
    const copyData = cloneDeep(bulkEditPayloadForPricing);
    let tempArray = [];
    selectedChannel.forEach((channel) => {
      if (channel === "stayflexi") {
        channel = "walkin";
      }
      tempArray.push(channel);
    });
    if (componentType === "set-price") {
      return copyData?.map((e, idx) => (
        <>
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Type")}:
              </Typography>
              <Typography className={classes.subText}>
                {rooms[e.roomTypeId]}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Rate Plan")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {
                  roomAndPlanscopy[e.roomTypeId].pricingInfoMap[e.ratePlanId]
                    .ratePlanName
                }
                {/* {ratePlans[e.ratePlanId]["ratePlanName"]} */}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Channels")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {tempArray.join(",")}
              </Typography>
            </Grid>
            {currentSelection?.map((item, idx) => (
              <Grid paddingBottom={1} display={"grid"} item xs={12}>
                <Typography className={classes.textDisplay}>
                  {t("Date Ranges")} :
                </Typography>

                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {`${moment(item?.startDate, "DD-MM-YYYY hh:mm:ss").format(
                    "MMMM Do YYYY"
                  )} - ${moment(item?.endDate, "DD-MM-YYYY hh:mm:ss").format(
                    "MMMM Do YYYY"
                  )}`}
                  {/* {`${moment(
                    new Date(
                      item.startDate.split(" ")[0].split("-")[2] +
                        "-" +
                        item.startDate.split(" ")[0].split("-")[1] +
                        "-" +
                        item.startDate.split(" ")[0].split("-")[0]
                    )
                  ).format("MMMM Do YYYY")} - ${moment(
                    new Date(
                      item.endDate.split(" ")[0].split("-")[2] +
                        "-" +
                        item.endDate.split(" ")[0].split("-")[1] +
                        "-" +
                        item.endDate.split(" ")[0].split("-")[0]
                    )
                  ).format("MMMM Do YYYY")} `} */}
                </Typography>
              </Grid>
            ))}
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Modified prices")} :
              </Typography>
              {Object.keys(pricesObj[idx])
                .filter(
                  (keyName) =>
                    pricesObj[idx][keyName] !== "" &&
                    !isNaN(pricesObj[idx][keyName])
                )
                .map((data) => (
                  <Typography
                    key={data}
                    className={classes.subText}
                    variant="body"
                  >
                    {data === "c" &&
                      `${t("Child price")} (${
                        roomAndPlans[e.roomTypeId]?.lchildAge
                      }-${roomAndPlans[e.roomTypeId]?.rchildAge}  ) - ${
                        pricesObj[idx][data]
                      }`}
                    {data === "c1" &&
                      `${t("Child price")} (${
                        roomAndPlans[e.roomTypeId]?.lchildAge1
                      }-${roomAndPlans[e.roomTypeId]?.rchildAge1}) - ${
                        pricesObj[idx][data]
                      }`}
                    {data !== "c" &&
                      data !== "c1" &&
                      `${data} ${t("Adults price")} - ${pricesObj[idx][data]}`}
                  </Typography>
                ))}
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <div
                style={{
                  borderTop: "2px solid black",
                  marginTop: "1rem",
                }}
              ></div>
            </Grid>
          </Grid>
        </>
      ));
    }

    if (componentType === "set-inventory") {
      return (
        <Grid container>
          <Grid display={"flex"} paddingBottom={1} item xs={12}>
            <Typography className={classes.textDisplay}>Room Type :</Typography>
            <Typography className={classes.subText}>
              {bulkEditPayload?.map((e) => rooms[e.roomTypeName]).join(",")}
            </Typography>
          </Grid>

          <Grid paddingBottom={1} display={"flex"} item xs={12}>
            <Typography className={classes.textDisplay}>
              {" "}
              {t("Room count")} :
            </Typography>
            <Typography className={classes.subText} variant="body">
              {bulkEditPayload?.map((e) => e.roomCount).join(",")}
            </Typography>
          </Grid>
          <Grid paddingBottom={1} display={"grid"} item xs={12}>
            <Typography className={classes.textDisplay}>
              {t("Date Ranges")} :
            </Typography>
            {currentSelection.map((data) => (
              <Typography
                className={classes.subText}
                key={data.id}
                variant="body"
              >
                {data.text}
              </Typography>
            ))}
          </Grid>
        </Grid>
      );
    }
  };
  const setSubmit = () => {
    try {
      switch (componentType) {
        case "set-price":
          setShowConfirm(false);
          setApiLoading(true);
          let tempArray = [];
          selectedChannel.forEach((channel) => {
            if (channel === "stayflexi") {
              channel = "walkin";
            }
            tempArray.push(channel);
          });
          let tempData = cloneDeep(bulkEditPayloadForPricing);
          const guest = tempData?.map((data, idx) => {
            let temp = data;
            temp["roomType"] = rooms[data?.roomTypeId];
            temp["occupancyPricing"] = pricesObj[idx];
            temp[`sources`] = tempArray;
            return temp;
          });
          const arr = [];
          for (let i = 0; i < currentSelection?.length; i++) {
            guest.map((item) => {
              let tempItem = { ...item };
              tempItem[`startDate`] = currentSelection[i].startDate;
              tempItem[`endDate`] = currentSelection[i].endDate;
              arr.push(tempItem);
            });
          }

          axios
            .post(
              `${process.env.REACT_APP_BG_API_URL}core/api/v1/pricing/bulk-set-multiple-source-occupancy-pricing`,
              arr
            )
            .then((res) => {
              if (res.code === "500") {
                toast.error(
                  `${res.message} - ${t("Cannot update price below min price")}`
                );
                setbulkEditPayloadForPricing([
                  {
                    roomType: "",
                    roomTypeId: "",
                    ratePlanId: "",
                    startDate: currentSelection[0]?.startDate,
                    endDate: currentSelection[0]?.endDate,
                    broadcastRates: true,
                    occupancyPricing: {},
                    sources: [],
                    daysIncluded: selectDays,
                    changeBaseRate: true,
                  },
                ]);
                setApiLoading(false);
              } else {
                toast.success(`${t("Successfully updated the pricing")}`);
                setbulkEditPayloadForPricing([
                  {
                    roomType: "",
                    roomTypeId: "",
                    ratePlanId: "",
                    startDate: currentSelection[0]?.startDate,
                    endDate: currentSelection[0]?.endDate,
                    broadcastRates: true,
                    occupancyPricing: {},
                    sources: [],
                    daysIncluded: selectDays,
                    changeBaseRate: true,
                  },
                ]);
              }
              setApiLoading(false);
              /* amplitude
                .getInstance()
                .logEvent(
                  "USER PERFORMING SET PRICING OPERATION IN BULK OPERATIONS",
                  amplitudeEventProperties
                ); */
              // setPricesObj([{}]);
            })
            .catch((err) => {
              toast.error(err.response.data);
              setApiLoading(false);
            });
          // await setPricingFunc().then((res) => {
          //   // handleShow();

          // });
          break;
        case "set-inventory":
          // await setInventoryFunc().then((res) => {
          //   // handleShow();
          //   amplitude
          //     .getInstance()
          //     .logEvent(
          //       "USER PERFORMING SET INVENTORY OPERATION IN BULK OPERATIONS",
          //       amplitudeEventProperties
          //     );
          // });
          break;
      }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
      setApiLoading(false);
    } finally {
      setCurrentDateSelection([
        {
          startDate: new Date(new Date().setHours(0, 0, 0, 0)),
          endDate: new Date(
            new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
              0,
              0,
              0,
              0
            )
          ),
        },
      ]);
      setCurrentSelection([]);
      // setPricesObj([{}]);
      formik.resetForm();
      setShowConfirm(false);
      refresh();
    }
  };

  const setInventoryFunc = async () => {
    // const arr = [];
    // let tempData = cloneDeep(bulkEditPayload);
    // const guest = tempData?.map((data) => {
    //   let temp = data;
    //   temp["roomTypeName"] = rooms[data?.roomTypeName];
    //   return temp;
    // });
    // for (let i = 0; i < currentSelection?.length; i++) {
    //   guest.map((item) => {
    //     item[`fromDate`] = moment(currentSelection[i].startDate).format(
    //       dateFormat
    //     );
    //     item[`toDate`] = moment(currentSelection[i].endDate).format(dateFormat);
    //     arr.push(item);
    //   });
    // }
    // console.log("arr", arr);
    // handleSpringDataMutationRequest(
    //   "PUT",
    //   "core/api/v1/inventory/bulk-set-pms-inventory-count",
    //   guest
    // )
    //   .then((res) => {
    //     toast.success("Successfully updated the inventory");
    //     setbulkEditPayload([
    //       {
    //         roomTypeName: "",
    //         fromDate: currentSelection[0]?.startDate,
    //         toDate: currentSelection[0]?.endDate,
    //         roomCount: "",
    //         daysIncluded: selectDays,
    //       },
    //     ]);
    //   })
    //   .catch((err) => toast.error(err));
    // await currentSelection.forEach(async (dateranges) => {
    //   const resp = await setPmsInventory({
    //     roomTypeName: rooms[selectedRoom],
    //     fromDate: dateranges.startDate,
    //     toDate: dateranges.endDate,
    //     roomCount: formik.values.roomCount,
    //     daysIncluded: dateranges.daysIncluded,
    //   });
    // });
    // getAllDatas();
  };

  const setPricingFunc = async () => {
    if (selectedChannel && selectedChannel?.length === 0) {
      toast.error("Please select at least one source");
      return;
    }
    let pricingMap = {};
    Object.keys(pricesObj).forEach((key) => {
      if (pricesObj[key] !== "" && !isNaN(pricesObj[key])) {
        pricingMap[key] = pricesObj[key];
      }
    });

    let payload = [];
    let tempArray = [];
    selectedChannel.forEach((channel) => {
      if (channel === "stayflexi") {
        channel = "walkin";
      }
      tempArray.push(channel);
    });
    currentSelection.forEach((daterange) => {
      payload.push({
        roomType: rooms[selectedRoom],
        roomTypeId: selectedRoom,
        ratePlanId: selectedRatePlan,
        startDate: daterange.startDate,
        endDate: daterange.endDate,
        broadcastRates: true,
        occupancyPricing: pricingMap,
        sources: tempArray,
        daysIncluded: daterange.daysIncluded,
        changeBaseRate: true,
      });
    });
    const data = await setPricingInventory(payload);
    if (data.code === "500") {
      toast.error(`${t("Can not update price below minimum set price")}`);
    } else {
      toast.success(`${t("Succesfully updated price")}`);
      // getAllDatas();
    }
  };

  const setRoomData = async (params) => {
    const resp = await getRoomInformation(params);
    setCompleteRoomInfo(resp);
    setRatePlans(resp.pricingInfoMap);
    let obj = Object.keys(resp.pricingInfoMap);
    setSelectedRatePlan(obj[0]);
  };

  useEffect(async () => {
    const channels = await getChannels();
    setChannels(channels);
    setSelectedChannel(channels);
  }, []);

  const [ratePlanArrayWithOrder, setRatePlanArrayWithOrder] = useState([]);

  useEffect(() => {
    if (Object.keys(ratePlans).length > 0) {
      const arr = [];
      Object.entries(ratePlans).map(([key, value]) => {
        const obj = { id: key };
        Object.entries(value).map(([k, v]) => {
          if (k === "ratePlanName") {
            obj.ratePlanName = v;
          } else if (k === "orderId") {
            obj.orderId = parseInt(v);
          }
        });
        arr.push(obj);
      });

      arr.sort((first, second) => {
        // console.log("first.orderId : ", first.orderId, " second.orderId : ", second.orderId)
        return first.orderId - second.orderId;
      });
      setRatePlanArrayWithOrder(arr);
    }
  }, [ratePlans]);

  const handleChannelChange = (event) => {
    // const {
    //   target: { value },
    // } = event;

    // if (value.length <= 0) {
    //   return;
    // }
    const val = event.target.value;
    if (val[val.length - 1] === "all") {
      setSelectedChannel(
        selectedChannel.length === channels.length ? [] : channels
      );

      return;
    }
    setSelectedChannel(val);
    // setSelectedChannel(
    //   // On autofill we get a the stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 0) {
      return;
    }
    setSelectDays(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setCurrentSelection([]);
  };
  const classes = useStyles();
  const theme = useTheme();
  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const handleChipDelete = (val) => {
    const arr = currentSelection.filter((chip) => chip.id !== val.id);
    formik.setFieldValue("currentDateSelection", arr);
    setCurrentSelection(arr);
  };

  const [isDisabled, setisDisabled] = useState(false);
  useEffect(() => {
    if (componentType === "set-inventory") {
      const data = bulkEditPayload?.filter((item) => {
        if (item?.roomTypeName === "" || item?.roomCount === "") {
          setisDisabled(true);
          setApiLoading(true);
        } else {
          setisDisabled(false);
          setApiLoading(false);
        }
      });
    } else {
      const data = bulkEditPayloadForPricing?.filter((item) => {
        if (item?.roomTypeId === "" || item?.ratePlanId === "") {
          setisDisabled(true);
          setApiLoading(true);
        } else {
          setisDisabled(false);
          setApiLoading(false);
        }
      });
    }
  }, [bulkEditPayload, bulkEditPayloadForPricing]);

  const getDisabled = (name) => {
    let flag = false;
    for (let i = 0; i < bulkEditPayload?.length; i++) {
      if (name === bulkEditPayload[i].roomTypeName) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  const getDisabledPricing = (roomType, ratePlan) => {
    let flag = false;
    for (let i = 0; i < bulkEditPayloadForPricing?.length; i++) {
      if (
        bulkEditPayloadForPricing[i].ratePlanId === ratePlan &&
        bulkEditPayloadForPricing[i].roomTypeId === roomType
      ) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  return (
    <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container>
          {!loading && (
            <Grid container className={classes.textField} spacing={2}>
              {componentType === "set-price" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl fullWidth>
                    <InputLabel id="source-chip">{t("Source")}</InputLabel>
                    <Select
                      labelId="source-chip"
                      id="source-multiple-chip"
                      multiple
                      value={selectedChannel}
                      onChange={handleChannelChange}
                      input={
                        <OutlinedInput
                          id="multiple-source"
                          label={t("Source")}
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              key={value}
                              label={value}
                              style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                              }}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: isAllSelected ? classes.selectedAll : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{
                              indeterminate: classes.indeterminateColor,
                            }}
                            checked={isAllSelected}
                            indeterminate={
                              selectedChannel.length > 0 &&
                              selectedChannel.length < channels.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary={t("Select All")}
                        />
                      </MenuItem>
                      {channels.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, channels, theme)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedChannel.indexOf(name) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={name} />
                          {/* {name} */}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="days-multiple-chip-label">
                    {t("Days")}
                  </InputLabel>
                  <Select
                    labelId="days-multiple-chip-label"
                    id="days-multiple-chip"
                    multiple
                    value={selectDays}
                    onChange={handleChange}
                    input={
                      <OutlinedInput id="day-multiple-chip" label={t("Days")} />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            variant="outlined"
                            color="primary"
                            key={value}
                            label={getValue(value)}
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {newweekdays.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.id}
                        style={getStyles(name, selectDays, theme)}
                      >
                        {name.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <ClickAwayListener
                    onClickAway={() => {
                      setShowDatePicker(false);
                      setAnchorEl(null);
                    }}
                  >
                    <div>
                      <TextField
                        fullWidth
                        focused={showDatePicker}
                        required
                        id="currentDateSelection"
                        name="currentDateSelection"
                        error={
                          formik.touched.currentDateSelection &&
                          Boolean(formik.errors.currentDateSelection)
                        }
                        helperText={
                          formik.touched.currentDateSelection &&
                          formik.errors.currentDateSelection
                        }
                        InputProps={{ readOnly: true }}
                        value={getDateText()}
                        label={t("Date range")}
                        onClick={(eve) => {
                          setAnchorEl(eve.currentTarget);
                          setShowDatePicker(true);
                        }}
                      ></TextField>
                      {showDatePicker && (
                        <Popper
                          style={{ zIndex: 2 }}
                          open={showDatePicker}
                          anchorEl={anchorEl}
                          placement="bottom"
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              style={{
                                transformOrigin: (placement = "bottom-end"),
                              }}
                              {...TransitionProps}
                            >
                              <Paper
                                elevation={5}
                                className="inventory-daterange-custom"
                              >
                                <DateRange
                                  color="#1BC5BD"
                                  rangeColors={["#1BC5BD"]}
                                  editableDateInputs={false}
                                  minDate={new Date()}
                                  moveRangeOnFirstSelection={false}
                                  onChange={(data) => {
                                    setCurrentDateSelection([
                                      { ...data.range1 },
                                    ]);
                                  }}
                                  ranges={currentDateSelection}
                                ></DateRange>
                                <Grid
                                  container
                                  item
                                  sx={{
                                    padding: 1,
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    sx={{ marginRight: 1 }}
                                    onClick={() => {
                                      setShowDatePicker(false);
                                    }}
                                    color="error"
                                  >
                                    {t("Cancel")}
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      const text = getDateText();
                                      const check = text + selectDays.join(",");
                                      if (
                                        currentSelection.findIndex(
                                          (val) => val.id === check
                                        ) === -1
                                      ) {
                                        currentSelection.push({
                                          text: text,
                                          id: check,
                                          datesText: selectDays.join(","),
                                          startDate: moment(
                                            currentDateSelection[0].startDate
                                          ).format(dateFormat),
                                          endDate: moment(
                                            currentDateSelection[0].endDate
                                          ).format(dateFormat),
                                          daysIncluded: selectDays,
                                        });
                                        formik.setFieldValue(
                                          "currentDateSelection",
                                          currentSelection
                                        );
                                        setCurrentSelection(currentSelection);
                                      }
                                      setShowDatePicker(false);
                                    }}
                                    color="primary"
                                  >
                                    {t("Apply")}
                                  </Button>
                                </Grid>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      )}
                    </div>
                  </ClickAwayListener>
                </div>
              </Grid>
              {currentSelection.length > 0 && (
                <Fade in={currentSelection.length > 0}>
                  <Grid item xs={12}>
                    <InputLabel>{t("Current Selections")}</InputLabel>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {currentSelection.map((data) => {
                        return (
                          <Chip
                            sx={{
                              width: "auto",
                              height: "auto",
                              fontWeight: "bold",
                            }}
                            variant="outlined"
                            color="primary"
                            onDelete={(eve) => {
                              handleChipDelete(data);
                            }}
                            label={
                              <div>
                                <Typography
                                  sx={{ padding: 0.5, fontWeight: "bold" }}
                                >
                                  {" "}
                                  {data.text}
                                </Typography>
                                <Divider />
                                <Typography
                                  sx={{
                                    padding: 0.5,
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                    fontWeight: "bold",
                                  }}
                                  display="inline-block"
                                >
                                  {" "}
                                  {data.datesText}
                                </Typography>
                              </div>
                            }
                            key={data.id}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                </Fade>
              )}

              {componentType === "set-inventory" &&
                bulkEditPayload?.map((item, index) => (
                  <Grid item spacing={2} container>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple">
                          {t("Room Types")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple"
                          id="multiple-rateplans"
                          value={item?.roomTypeName}
                          label={t("Room Types")}
                          MenuProps={MenuProps}
                          name="roomTypeName"
                          onChange={(e) => updateInventory(index, e)}
                          // onChange={handleRateplanChange}
                        >
                          {Object.keys(roomAndPlans).map((room) => (
                            <MenuItem
                              key={room}
                              value={room}
                              disabled={getDisabled(room)}
                            >
                              {roomAndPlans[room]?.roomTypeName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      justifyContent="flex-start"
                      container
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <TextField
                        id="roomCount"
                        name="roomCount"
                        required
                        label={t("Room Count")}
                        value={item?.roomCount}
                        onChange={(e) => updateInventory(index, e)}
                        type="number"
                      ></TextField>
                    </Grid>

                    {index !== 0 && (
                      <Typography
                        onClick={() => removeInventory(index)}
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "15px",
                          cursor: "pointer",
                        }}
                      >
                        <AiIcons.AiOutlineClose size="20px" />
                      </Typography>
                    )}
                  </Grid>
                ))}
              {componentType === "set-inventory" && (
                <Button
                  style={{
                    width: "fit-content",
                    textTransform: "none",
                    marginLeft: "20px",
                    marginTop: "1rem",
                  }}
                  disabled={apiLoading}
                  // disabled={isDisabled}
                  onClick={addInventory}
                  variant="custom-button"
                >
                  {t("Add Room Type")}
                </Button>
              )}
              {bulkEditPayloadForPricing?.map((item, idx) => (
                <>
                  {componentType === "set-price" && (
                    <Grid xs={12} item container>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple">
                            {t("Room Types")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple"
                            id="multiple-rateplans"
                            value={item?.roomTypeId}
                            label={t("Room Types")}
                            className="mr-4 mb-4"
                            MenuProps={MenuProps}
                            name="roomTypeId"
                            onChange={(e) => updatePricing(idx, e)}
                            // onChange={handleRateplanChange}
                          >
                            {Object.keys(roomAndPlans).map(
                              (room) =>
                                roomAndPlans[
                                  room
                                ]?.activeDependency.toLowerCase() === "no" && (
                                  <MenuItem key={room} value={room}>
                                    {roomAndPlans[room]?.roomTypeName}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      {item?.roomTypeId !== null &&
                        item?.roomTypeId !== undefined &&
                        item?.roomTypeId !== "" && (
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple">
                                {t("Rate Plans")}
                              </InputLabel>
                              <Select
                                labelId="demo-simple"
                                id="multiple-rateplans"
                                value={item?.ratePlanId}
                                label={t("Rate Plans")}
                                name="ratePlanId"
                                MenuProps={MenuProps}
                                onChange={(e) => updatePricing(idx, e)}
                              >
                                {Object.entries(
                                  roomAndPlans[item?.roomTypeId]?.pricingInfoMap
                                )
                                  .sort(
                                    (a, b) =>
                                      parseInt(a[1].orderId) -
                                      parseInt(b[1].orderId)
                                  )
                                  ?.map(
                                    ([key, value]) =>
                                      value["activeDep"]?.toLowerCase() ===
                                        "no" && (
                                        <MenuItem
                                          key={key}
                                          value={key}
                                          disabled={getDisabledPricing(
                                            item?.roomTypeId,
                                            key
                                          )}
                                        >
                                          {value["ratePlanName"]}
                                        </MenuItem>
                                      )
                                  )}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      {idx !== 0 && (
                        <Typography
                          onClick={() => removePricing(idx)}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginLeft: "15px",
                            cursor: "pointer",
                          }}
                        >
                          <AiIcons.AiOutlineClose size="20px" />
                        </Typography>
                      )}
                      {item?.roomTypeId !== null &&
                        item?.roomTypeId !== undefined &&
                        item?.roomTypeId !== "" && (
                          <Grid
                            container
                            id="priceFields"
                            name="occupancyPricing"
                            spacing={2}
                          >
                            {[
                              ...Array(
                                roomAndPlans[item?.roomTypeId].maxOccupancy -
                                  roomAndPlans[item?.roomTypeId]
                                    .defaultOccupancy +
                                  1
                              ).keys(),
                            ].map((num, index) => {
                              let diff =
                                roomAndPlans[item?.roomTypeId].defaultOccupancy;
                              return (
                                <Grid
                                  item
                                  key={index + diff}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  xl={4}
                                >
                                  <TextField
                                    fullWidth
                                    // error={
                                    //   formik.touched.priceFields &&
                                    //   Boolean(formik.errors.priceFields)
                                    // }
                                    onChange={(eve) => {
                                      let tempPriceObj = pricesObj[idx];
                                      if (
                                        isNaN(eve.target.value) ||
                                        eve.target.value === ""
                                      ) {
                                        tempPriceObj[index + diff] = "";
                                      } else {
                                        tempPriceObj[index + diff] =
                                          tempPriceObj[index + diff] =
                                            eve.target.value;
                                      }

                                      formik.setFieldValue(
                                        "priceFields",
                                        Object.values(pricesObj).filter(
                                          (val) => val !== "" && !isNaN(val)
                                        )
                                      );

                                      const updatedBulkEdit = pricesObj.map(
                                        (bulkEdit, indexes) => {
                                          if (indexes === idx) {
                                            return tempPriceObj;
                                          } else {
                                            return bulkEdit;
                                          }
                                        }
                                      );
                                      setPricesObj(updatedBulkEdit);
                                      // setPricesObj({ ...pricesObj });
                                      // updatePricing(index, eve, pricesObj);
                                    }}
                                    value={
                                      isUndefined(
                                        pricesObj[idx][index + diff]
                                      ) || pricesObj[idx][index + diff] === ""
                                        ? ""
                                        : pricesObj[idx][index + diff]
                                    }
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label={`${t("Set Price for")} ${
                                      index + diff
                                    } ${t("Adults")}`}
                                  />
                                </Grid>
                              );
                            })}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <TextField
                                style={{ width: "49%", marginRight: "2%" }}
                                // error={
                                //   formik.touched.priceFields &&
                                //   Boolean(formik.errors.priceFields)
                                // }
                                onChange={(eve) => {
                                  let tempPriceObj = pricesObj[idx];
                                  if (isNaN(eve.target.value)) {
                                    tempPriceObj["c"] = "";
                                  } else {
                                    tempPriceObj["c"] = eve.target.value;
                                  }
                                  // formik.setFieldValue(
                                  //   "priceFields",
                                  //   Object.values(pricesObj).filter(
                                  //     (val) => val !== "" && !isNaN(val)
                                  //   )
                                  // );
                                  const updatedBulkEdit = pricesObj.map(
                                    (bulkEdit, indexes) => {
                                      if (indexes === idx) {
                                        return tempPriceObj;
                                      } else {
                                        return bulkEdit;
                                      }
                                    }
                                  );
                                  setPricesObj(updatedBulkEdit);
                                  // updatePricing(idx, eve, pricesObj);
                                }}
                                value={
                                  isUndefined(pricesObj[idx]["c"])
                                    ? ""
                                    : pricesObj[idx]["c"]
                                }
                                type="number"
                                label={t("Child price")}
                              />
                              <TextField
                                style={{ width: "49%" }}
                                // error={
                                //   formik.touched.priceFields &&
                                //   Boolean(formik.errors.priceFields)
                                // }
                                onChange={(eve) => {
                                  let tempPriceObj = pricesObj[idx];
                                  if (isNaN(eve.target.value)) {
                                    tempPriceObj["c1"] = "";
                                  } else {
                                    tempPriceObj["c1"] = eve.target.value;
                                  }
                                  // formik.setFieldValue(
                                  //   "priceFields",
                                  //   Object.values(pricesObj).filter(
                                  //     (val) => val !== "" && !isNaN(val)
                                  //   )
                                  // );
                                  const updatedBulkEdit = pricesObj.map(
                                    (bulkEdit, indexes) => {
                                      if (indexes === idx) {
                                        return tempPriceObj;
                                      } else {
                                        return bulkEdit;
                                      }
                                    }
                                  );
                                  setPricesObj(updatedBulkEdit);
                                  // updatePricing(idx, eve, pricesObj);
                                }}
                                value={
                                  isUndefined(pricesObj[idx]["c1"])
                                    ? ""
                                    : pricesObj[idx]["c1"]
                                }
                                type="number"
                                label={t("Infant price")}
                              />
                              <div
                                style={{
                                  borderTop: "2px solid black",
                                  marginTop: "1rem",
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={12}>
                              {/* <FormHelperText error>
                            {formik.touched.priceFields &&
                              formik.errors.priceFields}
                          </FormHelperText> */}
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  )}
                </>
              ))}
              {componentType === "set-price" && (
                <Button
                  style={{
                    width: "fit-content",
                    textTransform: "none",
                    marginLeft: "20px",
                    marginTop: "1rem",
                  }}
                  disabled={apiLoading}
                  // disabled={isDisabled}
                  onClick={addPricing}
                  variant="custom-button"
                >
                  {t("Add Room Type")}
                </Button>
              )}
            </Grid>
          )}
          {!loading && (
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                className={classes.textField}
                justifyContent="flex-end"
                container
              >
                <Fab
                  type="submit"
                  disabled={apiLoading}
                  variant="extended"
                  color="primary"
                >
                  {componentType === "set-price" && `${t("Set Pricing")}`}
                  {componentType === "set-inventory" && `${t("Set Inventory")}`}
                </Fab>
              </Grid>
            </Grid>
          )}

          <ConfirmationModal
            open={showConfirm}
            onClose={() => {
              setShowConfirm(false);
            }}
            onBackdropClick={closeFunc}
            title={
              DropdownOptions.find((item) => item.val === componentType).text
            }
            loading={apiLoading}
            displayData={
              showConfirm
                ? componentType === "set-price"
                  ? getDisplayData()
                  : null
                : null
            }
            confirmAction={setSubmit}
          ></ConfirmationModal>
        </Grid>
      </form>
    </Grow>
  );
};

export default SetPricing;
