import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import Stack from "@mui/material/Stack";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { handleNum } from "../../../Utils/Utils";
import { FormControl, InputLabel, Select, Button } from "@mui/material";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import axios from "axios";

function ModifyCheckin({
  onCancel,
  hotelId,
  bookingId,
  invoiceData,
  updateData,
  reloadGrid,
  reservationId,
  isGrid,
  updateSummaryData,
  data,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  /* Booking Info */
  const [bookingInfo, setBookingInfo] = useState(null);
  function getBookingInfo() {
    djangoHandleDataRequests(
      `invoice/getBookingInfoById?bookingID=${bookingId}`
    )
      .then((res) => setBookingInfo(res))
      .catch((err) => toast.error(`${t("Error fetching the data.")}`));
  }

  useEffect(() => {
    getBookingInfo();
  }, []);

  /* CHECKIN */

  // handling the date for checkin
  const [checkinDate, setCheckinDate] = useState(
    new Date(invoiceData?.invoice.checkin)
  );
  const handleChange = (newValue) => {
    setCheckinDate(newValue);
  };
  const [roomIdss, setroomIdss] = useState([]);

  useEffect(() => {
    setroomIdss(
      invoiceData.invoice.room_ids_list?.filter((e) => e !== "UNASSIGNED")
    );
  }, []);

  const [selectedRooms, setselectedRooms] = useState([
    invoiceData.invoice.room_ids_list[0],
  ]);

  useEffect(() => {
    setselectedRooms(roomIdss);
  }, [roomIdss]);
  // date formatting
  function handleDateFormat(date) {
    return moment(date).format("DD-MM-YYYY+hh:mm:ss");
  }

  // getting the checkin and the checkout slots
  // Based on the checkin and the checkout date

  // defining the slots
  const [slots, setSlots] = useState(null);

  function handleGetSlots() {
    handleSpringDataRequest(
      `core/api/v1/reservation/getCheckinAndCheckoutSlots/?checkin=${handleDateFormat(
        checkinDate
      )}&checkout=${handleDateFormat(checkoutCal)}`
    )
      .then((res) => setSlots(res))
      .catch((error) => toast.error(`${t("Error getting the slots.")}`));
  }

  useEffect(() => {
    if (checkinDate && checkoutCal) {
      handleGetSlots();
    }
  }, [checkinDate, checkoutCal]);

  // Formatting the slot time
  function handleSlotTimeFormat(slotTime) {
    return moment(slotTime.toString(), ["HH"]).format("hh:mm A");
  }

  // rate type and new rate for extending checkin
  const [rateType, setRateType] = useState("TOTAL_PRETAX");
  const [newRate, setNewRate] = useState(null);

  // extending checkin loader
  const [checkinLoader, setCheckinLoader] = useState(true);

  // getting the info based on the slots and the date --- checkin
  const [checkinInfo, setCheckinInfo] = useState(null);
  function handleGetCurrentSlot() {
    const resId = [];
    for (let i = 0; i < selectedRooms.length; i++) {
      resId.push(reservationId[selectedRooms[i]]);
    }
    setCheckinLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/getEditCheckinPricePayload",
      {
        hotelId: hotelId,
        // bookingId: bookingId,
        checkin: moment(checkinDate).format("DD-MM-YYYY HH:mm:ss"),
        newRate: newRate,
        newRateType: rateType,
        resIds: resId,
      }
    )
      .then((res) => {
        setCheckinInfo(res);
        setCheckinLoader(false);
      })
      .catch((error) => {
        toast.error(`${t("Error fetching the current slot!")}`);
        setCheckinLoader(false);
      });
  }

  useEffect(() => {
    setCheckinLoader(true);
    const timer = setTimeout(() => {
      if (bookingInfo) {
        handleGetCurrentSlot();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [checkinDate, bookingInfo, rateType, newRate, selectedRooms]);

  useEffect(() => {
    if (checkinInfo) {
      setPricePerNight(checkinInfo.perNightPrice.toFixed(2));
      setTotalBeforeTax(checkinInfo.priceBeforeTax.toFixed(2));
      setTotalTax(checkinInfo.taxValue.toFixed(2));
      setTotalWithTax(checkinInfo.priceAfterTax.toFixed(2));
    }
  }, [checkinInfo]);

  // handling the new check in slot

  // setting the initial value for the checkin slot
  let currentChekcinSlotTime = moment(invoiceData?.invoice.checkin).format(
    "HH"
  );
  useEffect(() => {
    if (slots) {
      if (slots.checkinSlots.some((item) => item === currentChekcinSlotTime)) {
        setCurrentSlot(currentChekcinSlotTime);
      } else {
        setCurrentSlot(slots.checkinSlots[0]);
      }
    }
  }, [slots]);

  const [currentSlot, setCurrentSlot] = useState("");
  const handleUpdateSlot = (event) => {
    setCurrentSlot(event.target.value);
  };

  // Handling performing edit checkin
  function performEditCheckin() {
    if (selectedRooms?.length === 0) {
      toast.error(`${t("Please select room id")}`);
    } else {
      setCheckinLoader(true);
      const resId = [];
      for (let i = 0; i < selectedRooms.length; i++) {
        resId.push(reservationId[selectedRooms[i]]);
      }

      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/performEditCheckin`,
          {
            checkinDate: moment(checkinDate).format("DD-MM-YYYY HH:mm:ss"),
            gAdditionalAmt: checkinInfo.priceAfterTax,
            hotelId: hotelId,
            resIds: resId,
          }
        )
        .then((res) =>
          toast.success(
            `${t("Successfully modified checkin  for")} ${bookingId}`
          )
        )
        .then((res) => handleGetCurrentSlot())
        .then((res) => setCheckinLoader(false))
        .then((response) => updateData())
        .then((response) => updateSummaryData())
        .then((res) => (isGrid ? reloadGrid() : ""))
        .then((res) => invalidateNightAuditReservation(resId[0]))
        .catch((error) =>
          toast.error(
            `${t("Something wrong happened. Please try again later.")}`
          )
        )
        .catch((error) => setCheckinLoader(false));
    }
  }

  // price per night
  const [pricePerNight, setPricePerNight] = useState(0);

  const handlePricePerNightChange = (event) => {
    setPricePerNight(event.target.value);
    setRateType("PER_NIGHT_PRETAX");
    setNewRate(event.target.value);
  };

  // Total price before tax
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);

  const handleTotalBeforeTaxChange = (event) => {
    setTotalBeforeTax(event.target.value);
    setRateType("TOTAL_PRETAX");
    setNewRate(event.target.value);
  };

  // Total Tax
  const [totalTax, setTotalTax] = useState(0);
  const handleTotalTaxChange = (event) => {
    setTotalTax(event.target.value);
  };

  // Total price after tax
  const [totalWithTax, setTotalWithTax] = useState(0);
  const handleTotalWithTaxChange = (event) => {
    setTotalWithTax(event.target.value);
    setRateType("TOTAL_WITHTAX");
    setNewRate(event.target.value);
  };

  /* CHECKOUT */

  // calendar
  const [checkoutCal, setCheckoutCal] = useState(
    new Date(invoiceData?.invoice.checkout)
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedRooms(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  useEffect(() => {}, [selectedRooms]);

  const invalidateNightAuditReservation = (id) => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/invalidate-night-audit-tracker-reservation?resId=${id}`
    )
      .then((res) => console.log())
      .catch((err) => console.error(err));
  };

  return (
    <div className="modifyDatesWrapper">
      <div className="accordianWrapper">
        <div className="modifyCheckInDates">
          <div className="newCheckInCalendar">
            <h4
            // style={{
            //   marginLeft: "2rem",
            //   marginTop: "2rem",
            //   marginBottom: "1rem",
            // }}
            >
              {t("Select the room id to modify checkin")}
            </h4>

            <div style={{ marginBottom: "2rem" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Room ids</InputLabel>
                <Select
                  value={selectedRooms}
                  onChange={handleChanges}
                  required="true"
                  MenuProps={MenuProps}
                  label={t("Room ids")}
                  multiple
                >
                  {roomIdss?.map((name) => (
                    <MenuItem key={name.id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <Stack spacing={4}>
            <Stack direction="row" spacing={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t("New check-in date")}
                  inputFormat="dd MMM, yyyy"
                  value={checkinDate}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      disabled
                      InputProps={{ readOnly: true }}
                      {...params}
                      sx={{ width: "75%" }}
                    />
                  )}
                  maxDate={checkoutCal}
                />
              </LocalizationProvider>

              <TextField
                id="outlined-select-currency"
                select
                label={t("New check-in slot")}
                value={currentSlot}
                onChange={handleUpdateSlot}
                // helperText="Select New CheckIn Slot"
                sx={{ width: "25%" }}
              >
                {slots?.checkinSlots.map((item, index) => (
                  <MenuItem key={index} value={item} className="checkInSlot">
                    {handleSlotTimeFormat(item)}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack direction="row" spacing={4}>
              <TextField
                id="outlined-multiline-flexible"
                label={t("Price per night")}
                multiline
                // rows={1}
                value={pricePerNight}
                sx={{ width: "100%" }}
                onChange={handlePricePerNightChange}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.EditBookingPrice
                  ).read === "YES"
                    ? false
                    : true
                }
              />

              <TextField
                id="outlined-multiline-flexible"
                label={t("Total before tax")}
                multiline
                // rows={1}
                value={totalBeforeTax}
                sx={{ width: "100%" }}
                onChange={handleTotalBeforeTaxChange}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.EditBookingPrice
                  ).read === "YES"
                    ? false
                    : true
                }
              />
            </Stack>
            <Stack direction="row" spacing={4}>
              <TextField
                id="outlined-basic"
                label={t("Total tax")}
                multiline
                // rows={1}
                value={totalTax}
                sx={{ width: "100%" }}
                onChange={handleTotalTaxChange}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                id="outlined-multiline-flexible"
                label={t("Total with tax")}
                multiline
                // rows={1}
                value={totalWithTax}
                sx={{ width: "100%" }}
                onChange={handleTotalWithTaxChange}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.EditBookingPrice
                  ).read === "YES"
                    ? false
                    : true
                }
              />
            </Stack>
          </Stack>
          <Stack alignItems="flex-end" mt={2}>
            {checkinLoader === false ? (
              <Button
                variant="custom-button"
                onClick={() => performEditCheckin()}
                disabled={checkinLoader}
              >
                {t("Modify checkin")}
              </Button>
            ) : (
              <Button variant="custom-button">
                <CircularProgress size="15px" />
              </Button>
            )}
          </Stack>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(ModifyCheckin);
